export enum AiAgentNameEnum {
  DISCUSSION = 'discussion',
  SUPERVISOR = 'supervisor',
  TRADEMARK_SEARCH = 'trademark_search',
  PATENT_SEARCH = 'patent_search',
  PATENT_SEARCH_NUMBER = 'patent_search_number',
  PATENT_FEES = 'patent_fees',
  TRADEMARK_SEARCH_NUMBER = 'trademark_search_number',
  PRICE_CALCULATION = 'price_calculation',
  PROVIDER_SELECTOR = 'provider_selector',
  REGISTRATION = 'registration',
  HINT_GENERATION = 'hint_generation',
  // Create task
  CREATE_TASK = 'create_task',
  CREATE_TASK_TRADEMARK = 'create_task_trademark',
  CREATE_TASK_PATENT_DRAFTING = 'create_task_patent_drafting',
  CREATE_TASK_PATENT_FILLING = 'create_task_patent_filling',
  CREATE_TASK_DESIGN = 'create_task_design',
}
