<div class="wrapper sm:w-128">
  <div class="title">
    <h2 mat-dialog-title>{{ 'end-work' | transloco }}</h2>
    <button class="button-close" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <mat-progress-spinner *ngIf="gettingATask" mode="indeterminate" diameter="50"></mat-progress-spinner>

    <form [formGroup]="form">
      <!-- Description -->
      <mat-form-field class="mb-0">
        <mat-label>{{ 'description' | transloco }}</mat-label>
        <textarea
          matInput
          formControlName="description"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
        ></textarea>
      </mat-form-field>
      <!-- Files -->
      <div>
        <h3 class="mb-5">Attach files as an evidence of the task completion</h3>
        <div *ngIf="files.length > 0" class="file-list mb-4">
          <div class="file-list__item mb-1" *ngFor="let file of files; let i = index">
            <div class="flex">
              <div class="col file-list__item_icon mr-2">
                <mat-icon color="primary" [inline]="true">insert_drive_file</mat-icon>
              </div>
              <div class="col flex-auto">
                <div
                  class="file-list__item_name text-primary-500"
                  [matTooltip]="file.name"
                  matTooltipPosition="above"
                >{{ file.name }}
                </div>
                <div class="text-xss text-gray-500 file-list__item_size">{{ file.size | fileSize }}</div>
              </div>
              <div class="col file-list__item_btn-delete">
                <button mat-button class="p-0 ml-3 text-gray-300" matTooltip="Delete" (click)="removeFile(i)">
                  <mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center mb-3">
          <input type="file" #fileInput (change)="addFile($event)" style="display: none" [multiple]="true" />
          <button
            mat-button
            type="button"
            class="pl-0"
            [color]="'primary'"
            (click)="selectFile()"
            [disabled]="form.disabled"
          >
            <mat-icon>add</mat-icon>
            <span>{{ 'add_files' | transloco }}</span>
          </button>

          <span class="text-gray-500 flex-auto text-right"> Max upload size: 50 MB </span>
        </div>
        <ng-container *ngIf="files.length > 1 && task.oips.length">
          <div class="alert text-xs p-2 mb-3">Several documents will be grouped into one folder automatically</div>
          <div class="flex">
            <mat-form-field class="flex-auto">
              <mat-label>Folder name</mat-label>
              <input matInput formControlName="folderFileName" />
            </mat-form-field>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="tagsInstalled || (files.length > 0 && oip) || form.controls.addFileToOIP.value || oip">
        <div class="divider mb-4"></div>

        <div *ngIf="oip" class="mt-3 mb-5">
          <mat-checkbox visibility="visible" color="primary" formControlName="addFileToOIP">
            <div [matTooltip]="task.oips[0]?.name" matTooltipPosition="above" class="oip-name">
              Add files to the "{{ task.oips[0]?.name }}"
            </div>
          </mat-checkbox>
        </div>

        <ng-container *ngIf="form.controls.addFileToOIP.value">
          <!-- Tags-->
          <div class="mb-3">
            <mat-radio-group class="flex flex-wrap w-full radio-group gap-1" color="primary" formControlName="type">
              <mat-radio-button class="flex-auto" *ngFor="let enum of type | keyvalue" [value]="enum.value">
                <span
                  [innerHTML]="
                    enum.value === 'input'
                      ? 'Incoming (Patent Office sent to us)'
                      : 'Outgoing (We sent to Patent Office)'
                  "
                ></span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="flex">
            <mat-form-field class="flex-auto">
              <mat-select
                formControlName="subType"
                [disabled]="form.controls.type.invalid"
                placeholder="Select category (tag) *"
              >
                <mat-option *ngFor="let enum of subType | keyvalue" [value]="enum.value">
                  {{ enum.value | transloco }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="divider mb-4"></div>
          <!--    Comments-->
          <div *ngIf="!thisIsAnApplication">
            <button
              mat-button
              type="button"
              class="pl-0"
              [disabled]="form.disabled"
              *ngIf="!showAddComment"
              (click)="showAddComment = true"
            >
              <mat-icon>add</mat-icon>
              <span>Add a comment to the files (optional)</span>
            </button>

            <ng-container *ngIf="showAddComment">
              <div class="flex mb-3">
                <h2 class="flex-auto text-base">Add a comment (optional)</h2>
                <button mat-button class="p-0 ml-3 text-gray-300" matTooltip="Delete" (click)="closeCommentForm()">
                  <mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon>
                </button>
              </div>
              <mat-form-field class="w-full">
                <textarea
                  matInput
                  class="w-full"
                  rows="5"
                  placeholder="This comment will be sent to all participants"
                  formControlName="comment"
                ></textarea>
                <mat-hint align="end">{{ form.controls.comment.value?.length }} / 1000</mat-hint>
              </mat-form-field>
            </ng-container>
          </div>

          <!-- Filling date -->
          <div *ngIf="thisIsAnApplication">
            <h3 class="mb-5">Add application biblio. data*</h3>
            <mat-form-field class="oip-name-field">
              <mat-label>Official IP right name </mat-label>
              <input matInput formControlName="oipName" />
            </mat-form-field>
            <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
              <mat-form-field fxFlex *ngIf="oip.type !== enums.oipType.TRADEMARK else trademark">
                <mat-label>Application number</mat-label>
                <input matInput formControlName="requestNumber" />
              </mat-form-field>
              <ng-template #trademark>
                <app-search-ip-right-control
                  [autoCompletePanelWidth]="495"
                  fxFlex
                  label="Application number"
                  [required]="true"
                  formControlName="requestNumber"
                  [country]="oip.countries?.[0]"
                  [oipType]="oip.type"
                  (applicationNumber)="setApplicationNumber($event)"
                  (selected)="onSelected($event)"
                >
                </app-search-ip-right-control>
              </ng-template>

              <app-date-input
                fxFlex
                label="{{ 'request_date' | transloco }}"
                formControlName="requestDate"
                [required]="true"
              >
              </app-date-input>
            </div>

            <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutGap.gt-xs="20px">
              <mat-form-field fxFlex>
                <mat-label>{{ 'priority_number' | transloco }}</mat-label>
                <input matInput formControlName="priorityNumber" />
              </mat-form-field>
              <app-date-input fxFlex label="{{ 'priority_date' | transloco }}" formControlName="priorityDate">
              </app-date-input>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="pt-6">
    <div class="space"></div>
    <button
      mat-raised-button
      color="primary"
      class="modal-button yes-button h-9 w-28 text-center"
      *ngIf="!form.disabled"
      [disabled]="files.length <= 0 || form.invalid"
      (click)="send()"
    >
      <span>{{ (task?.stage === enums.taskStages.REOPEN ? 'submit_revision' : 'send') | transloco }}</span>
    </button>
    <mat-progress-spinner *ngIf="form.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
  </mat-dialog-actions>
</div>
