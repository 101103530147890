import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import { Observable, of, throwError } from 'rxjs';
import { finalize, flatMap, mergeMap } from 'rxjs/operators';
import { Paged } from '../../../../dataset/Paged';
import { ICompanyEntity, IGetManyDefaultResponse, IOIPEntity } from '@ipnote/interface';
import { RequestQueryBuilderService } from '../../../../app-common/services/request-query-builder/request-query-builder.service';
import { concatLatestFrom } from '@ngrx/effects';
import { CustomActions, OIPSubStage } from '@ipnote/enum';
import { SpinnerViewService } from '../../../../app-common/services/spinner/spinner-view.service';

interface IAction {
  currentStage: string;
  currentSubStage: OIPSubStage;
  actionForExecute: CustomActions;
}

@Injectable({
  providedIn: 'root',
})
export class OIPService extends ApiService<IOIPEntity> {
  constructor(
    http: HttpClient,
    baseUrl: BaseUrlService,
    queryBuilder: RequestQueryBuilderService,
    spinnerService: SpinnerViewService,
  ) {
    super(
      http,
      baseUrl,
      queryBuilder,
      'oips',
      'oips',
      ['name', 'internalId', 'requestNumber', 'priorityNumber', 'releaseNumber'],
      spinnerService,
    );
  }

  isCompanyHasOip() {
    return this.baseUrl.getBaseUrl('oips/are-there-any-oips').pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      flatMap(([url, params]) => this.http.get<{ status: boolean }>(url, { params: { ...params, incoming: 'true' } })),
    );
  }

  getAllOip(
    pageIndex: number = 1,
    pageSize: number = 9999,
    searchToken?: string,
    searchFields?: string[],
    sort?: string,
    filter?: any,
    additionalOptions?: object,
    relations?: string[],
  ): Observable<IGetManyDefaultResponse<IOIPEntity>> {
    return this.baseUrl.getBaseUrl('oips').pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
          pageIndex,
          pageSize,
          searchToken,
          searchFields,
          sort,
          filter,
          additionalOptions,
          relations,
        }),
      ]),
      mergeMap(([url, params]) => this.http.get<IGetManyDefaultResponse<IOIPEntity>>(url, { params: { ...params } })),
    );
  }

  parents(id: string): Observable<Paged<IOIPEntity>> {
    return this.baseUrl.getBaseUrl(`oips/${id}/parents`).pipe(flatMap((url) => this.http.get<Paged<IOIPEntity>>(url)));
  }

  children(id: string): Observable<Paged<IOIPEntity>> {
    return this.baseUrl.getBaseUrl(`oips/${id}/children`).pipe(flatMap((url) => this.http.get<Paged<IOIPEntity>>(url)));
  }

  updateChildren(id: string, children: string[]): Observable<void> {
    return this.baseUrl.getBaseUrl(`oips/${id}/children`).pipe(flatMap((url) => this.http.put<void>(url, children)));
  }

  searchFips(id: string): Observable<any> {
    return this.baseUrl.getBaseUrl('oips/search/fips').pipe(
      flatMap((url) => this.http.get<any>(url, { params: { number: id } })),
      flatMap((res) => (res ? of(res) : throwError(new Error('not-found')))),
    );
  }

  makeAction(id: number, action: IAction): Observable<IOIPEntity> {
    return this.baseUrl.getBaseUrl(`oips/${id}/action`).pipe(flatMap((url) => this.http.post<IOIPEntity>(url, action)));
  }

  getUploadDataArm(): Observable<boolean> {
    return this.baseUrl.getBaseUrl('oips/import/arm').pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      flatMap(([url, params]) =>
        this.http.get<boolean>(url, {
          params,
        }),
      ),
    );
  }

  agents(limit: number, searchToken?: string): Observable<ICompanyEntity[]> {
    return this.baseUrl.getBaseUrl('companies/agents').pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
          searchToken,
          searchFields: ['name'],
        }),
      ]),
      flatMap(([url, param]) => this.http.get<ICompanyEntity[]>(url, { params: { ...param } })),
    );
  }

  access(id: number, body: any): Observable<IOIPEntity> {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl(`oips/${id}/sharing`).pipe(
      mergeMap((url) => {
        return this.http.patch<IOIPEntity>(url, body);
      }),
      finalize(() => this.spinnerService.stop()),
    );
  }

  unWatchTrademark(oipId: number) {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl(`oips/${oipId}/deltawatch/ `).pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      mergeMap(([url, params]) => this.http.delete<IOIPEntity>(url)),
      finalize(() => this.spinnerService.stop()),
    );
  }

  watchTrademark(oipId: number) {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl(`oips/${oipId}/deltawatch/ `).pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      mergeMap(([url, params]) => this.http.post<IOIPEntity>(url, {})),
      finalize(() => this.spinnerService.stop()),
    );
  }
}
