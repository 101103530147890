export enum ParameterType {
  STRING = 'String',
  NUMBER = 'Number',
  BOOLEAN = 'Boolean',
  DATE = 'Date',
  MULTIPLE_STRINGS = 'MultipleStrings',
}

export enum ParameterName {
  NUMBER_OF_WORDS = 'NumberOfWords',
  APPLICATION_DATE = 'ApplicationDate',
  ENTITY_SIZE = 'EntitySize',
  INDEPENDENT_CLAIM_COUNT = 'IndependentClaimCount',
  CLAIM_COUNT = 'ClaimCount',
  PRIORITY_COUNT = 'PriorityCount',
  SHEET_COUNT = 'SheetCount',
  MULTIPLE_DEPENDENT_CLAIM_COUNT = 'MultipleDependentClaimCount',
  DEPENDENT_CLAIM_COUNT = 'DependentClaimCount',
  ISA = 'ISA',
  IPRP = 'IPRP',
  VAL_CTY = 'VAL_CTY',
  EXTENSION = 'Extension',
  ISR_AVAILABLE = 'ISR_AVAILABLE',
  OFFICE_ELECTED = 'OfficeElected',
  RO_NP = 'RO_NP',
  COUNTRY_COUNT = 'CountryCount',
}
