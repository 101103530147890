import { ParameterName, ParameterType } from '../enums/parameter.enums';

export interface IParametersRes {
  inputs: string[];
}

export interface IParameterItem {
  Type: ParameterType;
  Name: ParameterName;
  ExpectedValues?: (string | boolean)[];
}
