import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ICompanyEntity } from '@ipnote/interface';

@Component({
  selector: 'app-avatar-photos',
  templateUrl: './avatar-photos.component.html',
  styleUrls: ['./avatar-photos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarPhotosComponent implements OnChanges {
  displayedCompanies: ICompanyEntity[];
  @Input() size = 32;
  @Input() companies: ICompanyEntity[];
  @Input() countOfDisplayedCompanies = 3;
  @Input() isAdaptive = false;

  isLogoExist: boolean;

  ngOnChanges() {
    this.displayedCompanies = this.companies.slice(0, this.countOfDisplayedCompanies);
  }
}
