<div class="onboarding">
  <ng-container *ngIf="!isMobile">
    <div class="onboarding-title" [innerHTML]="'onboarding-title' | transloco"></div>
    <div class="onboarding-text" [innerHTML]="'onboarding-text' | transloco"></div>
  </ng-container>
  <ng-container *ngIf="isMobile">
    <div class="onboarding-title text-center"[innerHTML]="'onboarding-title-mobile' | transloco"></div>
    <div class="onboarding-text text-center" [innerHTML]="'onboarding-text-mobile' | transloco"></div>
  </ng-container>

  <div class="onboarding-body flex-col sm:flex-row">
    <div class="relative">
      <div *ngFor="let item of descriptions; let isLast = last" class="onboarding-description">
        <div class="onboarding-description-item">
          <div class="onboarding-icon-wrapper">
            <img [src]='item.icon' alt="">
            <img *ngIf="item.addIcon" [src]='item.addIcon' class="add-icon" alt="">
          </div>
          <div class="onboarding-description-text" [innerHTML]="item.text | transloco"></div>
        </div>
        <div *ngIf="!isLast" class="onboarding-description-chain">
          <img src='assets/svg/onboarding/chain.svg' alt="">
        </div>
      </div>

      <button
        class="onboarding-button block sm:absolute mx-auto sm:mx-0 my-[40px] sm:my-0"
        mat-raised-button
        (click)="createTask()"
      >
        {{ 'start now' | transloco }}
        <img class="inline" src='assets/svg/onboarding/arrow-right.svg' alt="start now">
      </button>

    </div>

    <div class="ml-[7%] hidden sm:block">
      <app-youtube-player
        [width]="innerWidth/2"
        [height]="innerWidth/2/1.77"
        [videoId]="!isServiceProvider(selectedCompany) ? 'kXrzLyo8LgQ': 'eD6MdtncM9c'">
      </app-youtube-player>
    </div>
  </div>
</div>

<div class="onboarding-background hidden sm:block"></div>
