<mat-spinner *ngIf="$isUpdate | async" [diameter]="15"></mat-spinner>

<span
  *ngIf="tag && ($isUpdate | async) === false"
  class="truncate file-tag file-tag_type-{{folder}}"
  [matTooltip]="getTooltip(folder, tag)"
  matTooltipPosition="above"
  (click)="prevent($event)"
>
  {{folder + '_docs' | transloco}}: {{tag | transloco}}
  <!--  @TODO: IPNOTE-3411-->
  <!--  <ic-icon *ngIf="!readOnly" [icon]="icCloseCircle" (click)="deleteTag($event)" size="16px"></ic-icon>-->
</span>

<button mat-button
        type="button"
        (click)="prevent($event)"
        [color]="'primary'"
        *ngIf="(!tag && ($isUpdate | async) === false) && !readOnly"
        [matMenuTriggerFor]="fileTypeMenu">
  <mat-icon>add</mat-icon>
  <span>{{'add_tag' | transloco}}</span>
</button>
<mat-menu #fileTypeMenu="matMenu" yPosition="below" xPosition="before">
  <ng-container *ngFor="let type of fileTypes">
    <button [matMenuTriggerFor]="sub_menu" mat-menu-item>{{type.name|transloco}}</button>
    <ng-container>
      <mat-menu #sub_menu="matMenu">
        <button *ngFor="let subType of type.subTypes"
                (click)="selectFileTags(type.value, subType, $event)"
                mat-menu-item>
          {{subType |transloco}}
        </button>
      </mat-menu>
    </ng-container>
  </ng-container>
</mat-menu>
