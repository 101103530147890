<form [formGroup]="form" *ngIf="relationDescriptors" (ngSubmit)="save()">
  <div class="flex flex-col sm:flex-row">
    <div class="w-1/2">
      <h2 class="link-title" [class.subtitle]="commonView">
        {{'with_others_' + currentModel.toLowerCase()  | transloco }}
      </h2>
      <ng-container *ngFor="let model of getRelations('self')">
        <app-links-chips
          [target]="target"
          [commonView]="commonView"
          [disable]="model.disable"
          [label]="model.label"
          [modelName]="model.key"
          [placeholder]="model.placeholder"
          [searcher]="model.searcher"
          [formControlName]="model.key"
          (openModel)="openModel($event)"
          [currentEntity]="currentEntity"
          [exclude]="generateExclude(model.excludeFrom)">
        </app-links-chips>
      </ng-container>
    </div>
    <div class="w-1/2">
      <h2 class="link-title" [class.subtitle]="commonView">
        {{ 'others_entities' | transloco }}
      </h2>
      <ng-container *ngFor="let model of getRelations('other')">
        <app-links-chips
          [commonView]="commonView"
          [disable]="model.disable"
          [label]="model.label"
          [modelName]="model.key"
          [placeholder]="model.placeholder"
          [searcher]="model.searcher"
          [formControlName]="model.key"
          (openModel)="openModel($event)"
          [currentEntity]="currentEntity"
          [exclude]="generateExclude(model.excludeFrom)"></app-links-chips>
      </ng-container>
    </div>
  </div>
  <button *ngIf="!readOnly && !commonView"
          mat-raised-button
          type="submit"
          color="primary"
          [disabled]="form.invalid || form.disabled || readOnly || form.pristine">
    {{'save'|transloco}}
  </button>
  <small *ngIf="readOnly && !commonView" class="muted">{{'readers' | transloco}}</small>
</form>
