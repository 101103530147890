<div class="wrapper">
  <div class="title">
    <h2 mat-dialog-title [innerHTML]="'Upload files'"></h2>
  </div>
  <button class="button-close" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>

  <mat-dialog-content>
    <form [formGroup]="formFile">
      <!--    Comments-->
      <div>
        <button
          mat-button
          type="button"
          class="pl-0"
          [disabled]="formFile.disabled"
          *ngIf="!showAddComment"
          (click)="showAddComment = true"
        >
          <mat-icon>add</mat-icon>
          <span>Add a comment to the files</span>
        </button>

        <ng-container *ngIf="showAddComment">
          <div class="flex mb-3">
            <h2 class="flex-auto text-base">Add a comment (optional)</h2>
            <button mat-button class="p-0 ml-3 text-gray-300" matTooltip="Delete" (click)="closeCommentForm()">
              <mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon>
            </button>
          </div>
          <mat-form-field class="w-full">
            <textarea
              matInput
              class="w-full"
              rows="5"
              placeholder="This comment will be sent to all participants"
              formControlName="comment"
            ></textarea>
            <mat-hint align="end">{{ formFile.controls.comment.value?.length }} / 1000</mat-hint>
          </mat-form-field>
        </ng-container>
      </div>
      <div class="divider my-4"></div>
      <!--    Tags-->
      <div>
        <div class="mb-3">
          <mat-radio-group
            class="flex-col sm:flex-row gap-2 flex w-full radio-group"
            color="primary"
            formControlName="type"
          >
            <mat-radio-button class="flex-auto" *ngFor="let enum of type | keyvalue" [value]="enum.value">
              <span [innerHTML]="enum.value === 'input' ? FileCategory.INCOMING : FileCategory.OUTGOING"></span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="flex flex-col">
          <mat-form-field>
            <mat-select
              formControlName="subType"
              [disabled]="formFile.controls.type.invalid"
              placeholder="Select category (tag) *"
            >
              <mat-option *ngFor="let enum of subType | keyvalue" [value]="enum.value">
                {{ enum.value.toString() | transloco }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              <div *ngIf="isOipsLoading;else mat_label_task" class="flex items-center">
                <span>Loading...</span>
                <mat-spinner class="spinner" diameter="20"></mat-spinner>
              </div>
              <ng-template #mat_label_task>{{ 'Linked IP' | transloco }}</ng-template>
            </mat-label>
            <mat-select formControlName="oips"
                        [compareWith]="objectComparisonFunction">
              <mat-option>
                <ngx-mat-select-search
                  formControlName="oipFilterCtrl"
                  [placeholderLabel]="'search' | transloco"
                  [noEntriesFoundLabel]="'not-found' | transloco"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option [value]="'createOip'">Create new</mat-option>
              <mat-option [value]="'none'">None</mat-option>
              <mat-option *ngFor="let oip of filteredOips | async" [value]="oip">
                <div class="flex flex-auto">
                  <div class="links-item-overflow">{{oip.name}}</div>
                  <img [src]="oip?.countries[0] | countryFlag"
                       *ngIf="oip?.countries"
                       matTooltipPosition="left"
                       [matTooltip]="oip?.countries[0] | transloco"
                       class="country-flag">
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <ng-container
          *ngIf="formFile.controls.oips.value && formFile.controls.oips.value !== 'none'"
          [formGroup]="oipEntityForm"
        >
          <ng-container *ngIf="formFile.controls.subType.value === enums.inputFolderTypes.ADMISSION_NOTICE">
            <div class="text-base font-medium mb-2">Add application biblio.data*</div>
            <mat-form-field class="w-full oip-name-field">
              <mat-label>IP right name</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
            <div class="flex-col sm:flex-row flex  flex-nowrap sm:gap-4">
              <app-search-ip-right-control
                [autoCompletePanelWidth]="510"
                class="basis-1/2"
                label="Application number"
                formControlName="requestNumber"
                [country]="oip?.countries?.[0]"
                [oipType]="oip?.type"
                (selected)="onSelected($event)"
              >
                <button
                  stopPrevent
                  class="absolute top-[6px] right-[5px] text-primary-500"
                  (click)="openBidPopup($event)"
                >
                  <mat-icon aria-hidden="false" aria-label="link">link</mat-icon>
                </button>
              </app-search-ip-right-control>
              <app-date-input
                class="basis-1/2"
                label="{{ 'request_date' | transloco }}"
                formControlName="requestDate"
                [required]="true"
              >
              </app-date-input>
            </div>

            <div class="flex-col sm:flex-row flex  flex-nowrap sm:gap-4">
              <mat-form-field class="flex-basis-48 m-0">
                <mat-label>{{ 'priority_number' | transloco }}</mat-label>
                <input matInput formControlName="priorityNumber" />
                <button class="text-primary-500 absolute top-1 right-1" (click)="openPriorityPopup($event)">
                  <mat-icon>link</mat-icon>
                </button>
              </mat-form-field>
              <app-date-input
                class="flex-basis-48 m-0"
                label="{{ 'priority_date' | transloco }}"
                formControlName="priorityDate"
              >
              </app-date-input>
            </div>
          </ng-container>

          <ng-container *ngIf="formFile.controls.subType.value === enums.inputFolderTypes.SECURITY_DOCUMENT">
            <div class="text-base font-medium mb-2">Add patent/certificate biblio.data*</div>
            <div class="flex flex-nowrap justify-between">
              <mat-form-field class="flex-basis-48 m-0">
                <mat-label>{{ 'release_number' | transloco }}</mat-label>
                <input matInput formControlName="releaseNumber" />
                <button class="text-primary-500 absolute top-1 right-1" (click)="openPatentPopup($event)">
                  <mat-icon>link</mat-icon>
                </button>
              </mat-form-field>

              <app-date-input
                class="flex-basis-48 m-0"
                label="{{ 'Issue date' | transloco }}"
                formControlName="releaseDate"
                [required]="true"
              >
              </app-date-input>
            </div>
            <div class="flex flex-nowrap justify-between">
              <app-date-input
                class="flex-basis-48 m-0"
                label="{{ 'date_of_expiry' | transloco }}"
                formControlName="expiryDate"
              >
              </app-date-input>
              <app-date-input
                class="flex-basis-48 m-0"
                label="{{ 'prolongation_date' | transloco }}"
                formControlName="prolongationDate"
              >
              </app-date-input>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="divider mb-4"></div>
      <!--    Files-->
      <div>
        <div *ngIf="file" class="file-list mb-4">
          <div class="file-list__item mb-1">
            <div class="flex">
              <div class="col file-list__item_icon mr-2">
                <mat-icon color="primary" [inline]="true">insert_drive_file</mat-icon>
              </div>
              <div class="col flex-auto">
                <div
                  class="file-list__item_name text-primary-500"
                  [matTooltip]="file.name"
                  matTooltipPosition="above"
                >{{ file.name }}
                </div>
                <div class="text-xss text-gray-500 file-list__item_size">{{ file.size | fileSize }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <mat-progress-spinner
      *ngIf="formFile.disabled"
      [diameter]="24"
      [mode]="'indeterminate'"
    ></mat-progress-spinner>
    <button
      mat-raised-button
      color="primary"
      class="w-full modal-button yes-button h-9 w-28 text-center"
      *ngIf="!formFile.disabled"
      [disabled]="isDisabled"
      (click)="uploadFiles()"
    >
      <span>Upload</span>
    </button>
  </mat-dialog-actions>
</div>
