import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionType } from '@ipnote/type';
import { PermissionService } from './permission.service';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[permission]',
  standalone: true,
})
export class PermissionDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    public permissionService: PermissionService,
  ) {}

  @Input() permission: PermissionType[];
  @Input() visibility: 'hidden' | 'visible' = 'visible';

  public ngOnInit(): void {
    this.permissionService
      .checkRole(this.permission)
      .pipe(take(1))
      .subscribe((res) => {
        if (res && this.visibility === 'visible') {
          // If condition is true add template to DOM
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else if ((res && this.visibility === 'hidden') || !res) {
          // Else remove template from DOM
          this.viewContainer.clear();
        }
      });
  }
}
