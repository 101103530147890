import { CheckboxFilterType } from '../enums/checkbox-filter-type';

export interface CheckboxParams {
  pageIndex?: string | number;
  pageSize?: string | number;
  sort?: string;
  checkbox?: string;
}

export interface DealsCheckbox {
  id: number;
  label: string;
  value: CheckboxFilterType;
  checked: boolean;
}
