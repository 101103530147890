import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ICompanyEntity, IUserSignInRes } from '@ipnote/interface';
import { selectStateSelectedCompany, selectStateUserInfo } from '#selectors';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeviceDetectorService } from 'ngx-device-detector';
import { toggleChatWindow } from '../../../store/actions/ai-widget.actions';
import { UserSetCommand } from '../../../store/actions/user.actions';
import { selectStateAiWidgetWindow } from '../../../store/selectors/ai-widget.selectors';
import { AiWidgetWindowState } from '../../../store/reducers/ai-widget/ai-widget-window.reducer';
import { CompanyTypeEnum } from '@ipnote/enum';
import { isServiceProvider } from '../../utils/is-service-provider';

@UntilDestroy()
@Component({
  selector: 'app-onboarding-preview',
  templateUrl: './onboarding-preview.component.html',
  styleUrls: ['./onboarding-preview.component.scss'],
})
export class OnboardingPreviewComponent implements OnInit {
  public selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  public selectedCompany: ICompanyEntity;
  user: IUserSignInRes;
  user$: Observable<IUserSignInRes> = this.store.select(selectStateUserInfo);
  aiWindowState$: Observable<AiWidgetWindowState> = this.store.select(selectStateAiWidgetWindow);
  innerWidth;
  isOpenedAiWindow: boolean;
  descriptions = [
    {
      text: 'onboarding-text-chat',
      icon: 'assets/svg/onboarding/dialog.svg',
      addIcon: 'assets/svg/onboarding/gear.svg',
    },
    {
      text: 'onboarding-text-create',
      icon: 'assets/svg/onboarding/magnifying-glass.svg',
    },
    {
      text: 'onboarding-text-track',
      icon: 'assets/svg/onboarding/tracker.svg',
    },
  ];
  CompanyTypeEnum = CompanyTypeEnum;
  isServiceProvider = isServiceProvider;

  constructor(public store: Store<AppState>, private deviceService: DeviceDetectorService) {
    this.selectedCompany$.subscribe((company) => {
      this.selectedCompany = company;
    });
    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
    });
    this.aiWindowState$.pipe(untilDestroyed(this)).subscribe((state) => {
      this.isOpenedAiWindow = state.isChatWindowOpen;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  createTask() {
    this.store.dispatch(UserSetCommand({ command: 'create task' }));

    if (!this.isOpenedAiWindow) {
      this.store.dispatch(toggleChatWindow());
    }
  }
}
