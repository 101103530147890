import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICompanyEntity, INormalizedIpRight, IOIPEntity } from '@ipnote/interface';
import { filter } from 'rxjs/operators';
import { FileManagerService } from '../../../services/file-manager/file-manager.service';
import { IPrivateFiles } from '../../files/file.interface';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { selectStateSelectedCompany } from '#selectors';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '../../../services/dialogs/dialog.service';
import { TranslocoService } from '@ngneat/transloco';
import { EnumsService } from '../../../../page-modules/desk/services/enums/enums.service';
import { OIPService } from '../../../../page-modules/desk/services/oip/oip.service';
import { RemindersService } from '../../../../page-modules/desk/services/reminders/reminders.service';
import { FolderTypes, InputFolderTypes, OutputFolderTypes } from '@ipnote/type';
import { ChangeOipService } from '../../../../page-modules/desk/services/change-oip/change-oip.service';
import { TasksService } from '../../../../page-modules/desk/services/tasks/tasks.service';
import { FileCategory, OIPType } from '@ipnote/enum';

@UntilDestroy()
@Component({
  selector: 'app-modal-upload-to-folder',
  templateUrl: './modal-upload-to-folder.component.html',
  styleUrls: ['./modal-upload-to-folder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalUploadToFolderComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;
  public files: File[] = [];

  type = FolderTypes;
  subType: any = Object.values(InputFolderTypes);
  showAddComment = false;
  selectedCompany: ICompanyEntity;
  selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  formFolderFiles = new FormGroup({
    type: new FormControl(null, Validators.required),
    subType: new FormControl(null, Validators.required),
    folderName: new FormControl(`New folder ${this.data.privateFiles.groupCount + 1}`),
    comment: new FormControl(''),
  });
  oipEntityForm = new FormGroup({
    name: new FormControl(null),
    requestNumber: new FormControl(null, Validators.required), // Application number
    requestDate: new FormControl(null, Validators.required), // Filing date
    requestUrl: new FormControl(),
    priorityNumber: new FormControl(),
    priorityDate: new FormControl(),
    priorityUrl: new FormControl(),

    releaseNumber: new FormControl(null, Validators.required), // Document number
    releaseUrl: new FormControl(),
    releaseDate: new FormControl(null, Validators.required),
    expiryDate: new FormControl(),
    prolongationDate: new FormControl(),
  });
  oip: IOIPEntity;
  /**
   * Constructor
   */
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      privateFiles: IPrivateFiles;
      type: FolderTypes;
      subType: InputFolderTypes;
    },
    private matDialogRef: MatDialogRef<ModalUploadToFolderComponent>,
    private uploadService: FileManagerService,
    private tasksService: TasksService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private dialogs: DialogService,
    private translocoService: TranslocoService,
    public enums: EnumsService,
    private oipService: OIPService,
    private reminderService: RemindersService,
    private changeOipService?: ChangeOipService,
  ) {
    this.oipEntityForm.disable();
    this.selectedCompany$.pipe(untilDestroyed(this)).subscribe((company) => (this.selectedCompany = company));
    this.formFolderFiles.controls.type.valueChanges
      .pipe(filter((r) => this.formFolderFiles.controls.type.valid))
      .subscribe((res) => {
        this.formFolderFiles.controls.subType.reset();

        this.subType = res === FolderTypes.INPUT ? Object.values(InputFolderTypes) : Object.values(OutputFolderTypes);
      });

    this.formFolderFiles.controls.subType.valueChanges
      .pipe(filter((r) => this.formFolderFiles.controls.subType.valid))
      .subscribe((res) => {
        if (this.files.length === 1) {
          this.formFolderFiles.controls.folderName.clearValidators();
          this.formFolderFiles.controls.comment.clearValidators();
        }
      });
    this.oipEntityForm.controls.requestDate.valueChanges.pipe().subscribe((result) => {
      this.oipEntityForm.controls.priorityDate.setValue(result);
    });
  }

  async ngOnInit() {
    this.oip = await this.getOip();
    if (this.oip) {
      this.oipEntityForm.controls.requestNumber.setValue(this.oip.requestNumber);
      this.oipEntityForm.controls.requestDate.setValue(this.oip.requestDate);
      this.oipEntityForm.controls.requestUrl.setValue(this.oip.requestUrl);
      this.oipEntityForm.controls.priorityNumber.setValue(this.oip.priorityNumber);
      this.oipEntityForm.controls.priorityDate.setValue(this.oip.priorityDate);
      this.oipEntityForm.controls.priorityUrl.setValue(this.oip.priorityUrl);

      this.oipEntityForm.controls.name.setValue(this.oip.name);
      this.oipEntityForm.controls.releaseNumber.setValue(this.oip.releaseNumber);
      this.oipEntityForm.controls.releaseUrl.setValue(this.oip.releaseUrl);
      this.oipEntityForm.controls.releaseDate.setValue(this.oip.releaseDate);
      this.oipEntityForm.controls.expiryDate.setValue(this.oip.expiryDate);
      this.oipEntityForm.controls.prolongationDate.setValue(this.oip.prolongationDate);
    }
    this.formFolderFiles.controls.subType.valueChanges.subscribe((result) => {
      if (result === this.enums.inputFolderTypes.ADMISSION_NOTICE) {
        this.oipEntityForm.enable();
        this.oipEntityForm.controls.releaseNumber.disable();
        this.oipEntityForm.controls.releaseUrl.disable();
        this.oipEntityForm.controls.releaseDate.disable();
        this.oipEntityForm.controls.expiryDate.disable();
        this.oipEntityForm.controls.prolongationDate.disable();
      } else if (result === this.enums.inputFolderTypes.SECURITY_DOCUMENT) {
        this.oipEntityForm.enable();
        this.oipEntityForm.controls.requestNumber.disable();
        this.oipEntityForm.controls.requestDate.disable();
        this.oipEntityForm.controls.requestUrl.disable();
        this.oipEntityForm.controls.priorityNumber.disable();
        this.oipEntityForm.controls.priorityDate.disable();
        this.oipEntityForm.controls.priorityUrl.disable();
      } else {
        this.oipEntityForm.disable();
      }
    });
    this.formFolderFiles.controls.type.setValue(this.data.type); // if modal opened from status bar
    this.formFolderFiles.controls.subType.setValue(this.data.subType); // if modal opened from status bar
  }

  async getOip(): Promise<IOIPEntity> {
    return new Promise((resolve) =>
      this.changeOipService.subject$.pipe(untilDestroyed(this)).subscribe(
        async (oip) => {
          resolve(oip);
        },
        (err) => {
          this.dialogs.error(err);
        },
      ),
    );
  }
  openPatentPopup(event: MouseEvent): void {
    event.preventDefault();
    this.dialogs
      .patentChoice({
        title: this.translocoService.translate('patent'),
        release: this.oipEntityForm.get('releaseUrl').value,
      })
      .pipe(filter((p) => !!p))
      .subscribe((result) => {
        if (result.release === '') {
          this.oipEntityForm.controls.releaseUrl.setValue(null);
          this.oipEntityForm.markAsDirty();
        } else {
          this.oipEntityForm.controls.releaseUrl.setValue(result.release);
          this.oipEntityForm.markAsDirty();
        }
      });
  }

  addFile($event: Event): void {
    // tslint:disable-next-line:deprecation
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    this.files.push(...Array.from(target.files));
    this.changesListFiles();
  }

  removeFile(index: number): void {
    this.files.splice(index, 1);
    this.changesListFiles();
  }

  changesListFiles(): void {
    if (this.files.length >= 1) {
      this.formFolderFiles.controls.folderName.setValidators(Validators.required);
    } else {
      this.formFolderFiles.controls.folderName.clearValidators();
    }
  }

  selectFile(): void {
    this.fileInput.nativeElement.click();
  }

  closeCommentForm(): void {
    this.formFolderFiles.controls.comment.reset('');
    this.showAddComment = false;
  }

  async uploadFiles(andCreateATask: boolean = false, createReminder: boolean = false): Promise<void> {
    if (!this.data.privateFiles) {
      return;
    }
    const { model, modelId } = this.data.privateFiles;
    const { type, subType, folderName, comment } = this.formFolderFiles.getRawValue();

    this.formFolderFiles.disable();

    const notIsFolder = this.files.length <= 1;

    const upload = notIsFolder
      ? this.uploadService.uploadPrivate(model, modelId, this.files[0], [type, subType], comment).pipe()
      : this.uploadService.uploadGroupFile(this.files, [type, subType], folderName, comment, modelId, model);

    upload.pipe(untilDestroyed(this)).subscribe(
      async (fileEntity) => {
        if (this.oipEntityForm.status !== 'DISABLED') {
          await this.oipService.update(this.data.privateFiles.modelId, this.oipEntityForm.value).toPromise();
        }
        this.formFolderFiles.reset();
        this.files = [];

        if (andCreateATask) {
          this.tasksService
            .creatingATaskFromAFile({
              oipId: modelId,
              fileId: notIsFolder ? fileEntity.id : null,
              folderId: !notIsFolder ? fileEntity.id : null,
            })
            .subscribe((task) => {
              this.router.navigateByUrl(`/desk/${this.selectedCompany.id}/tasks/${task.taskId}/info/general`);
              this.matDialogRef.close(null);
            });
        } else if (createReminder) {
          this.reminderService
            .creatingReminderFromFile({
              oipId: modelId,
              fileId: notIsFolder ? fileEntity.id : null,
              folderId: !notIsFolder ? fileEntity.id : null,
            })
            .subscribe((reminder) => {
              this.router.navigateByUrl(
                `/desk/${this.selectedCompany.id}/oips/${this.data.privateFiles.modelId}/reminders/${reminder.reminderId}`,
                { replaceUrl: true },
              );
              this.matDialogRef.close(null);
            });
        } else {
          this.matDialogRef.close(fileEntity);
        }
      },
      (err) => {
        this.dialogs.error(err);
        this.formFolderFiles.enable();
      },
    );
  }

  openBidPopup(event: MouseEvent): void {
    event.preventDefault();
    this.dialogs
      .bidChoice({
        title: this.translocoService.translate('bid'),
        request: this.oipEntityForm.get('requestUrl').value,
      })
      .pipe(filter((p) => !!p))
      .subscribe((result) => {
        if (result.request === '') {
          this.oipEntityForm.controls.requestUrl.setValue(null);
          this.oipEntityForm.markAsDirty();
        } else {
          this.oipEntityForm.controls.requestUrl.setValue(result.request);
          this.oipEntityForm.markAsDirty();
        }
      });
  }

  openPriorityPopup(event: MouseEvent): void {
    event.preventDefault();
    this.dialogs
      .priorityChoice({
        title: this.translocoService.translate('priority_document'),
        request: this.oipEntityForm.get('priorityUrl').value,
      })
      .pipe(filter((p) => !!p))
      .subscribe((result) => {
        if (result.request === '') {
          this.oipEntityForm.controls.priorityUrl.setValue(null);
          this.oipEntityForm.markAsDirty();
        } else {
          this.oipEntityForm.controls.priorityUrl.setValue(result.request);
          this.oipEntityForm.markAsDirty();
        }
      });
  }

  onSelected($event: { data: INormalizedIpRight; index: number }) {
    const { name, requestNumber, requestDate, priorityNumber, priorityDate } = $event.data;

    this.oipEntityForm.controls.name.setValue(name);
    this.oipEntityForm.controls.requestNumber.setValue(requestNumber);
    this.oipEntityForm.controls.requestDate.setValue(requestDate);
    this.oipEntityForm.controls.priorityNumber.setValue(priorityNumber);
    this.oipEntityForm.controls.priorityDate.setValue(priorityDate);
  }

  setApplicationNumber(applicationNumber: number): void {
    this.oipEntityForm.controls.requestNumber.setValue(applicationNumber);
  }
}
