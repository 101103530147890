import { Component, Input } from '@angular/core';
import { Country, Protocols, Territory } from '@ipnote/enum';

@Component({
  selector: 'app-country-flag',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.scss'],
})
export class CountryFlagComponent {
  @Input() country?: Country | Territory | Protocols;
  @Input() isDisabledTooltip = false;
}
