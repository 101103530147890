<mat-form-field class="w-full">
  <mat-label>{{ label }}</mat-label>
  <input matInput [formControl]="ipRightNumber" [matAutocomplete]="auto" (focus)="onIpRightNumberInputFocus()"/>
  <mat-autocomplete [panelWidth]="autoCompletePanelWidth" #auto="matAutocomplete">
    <mat-option disabled *ngIf="isLoading; else elseBlock" class="is-loading">Loading...</mat-option>
    <ng-template #elseBlock>
      <ng-container *ngIf="filteredIpRights?.length > 0">
        <mat-option
          (click)="onSelect(trademark, i)"
          *ngFor="let trademark of filteredIpRights; let i = index"
          [value]="trademark"
        >
          <span>
            {{ trademark?.name }},
            {{
              trademark?.requestNumber
                ? 'application: ' + trademark?.requestNumber + ', ' + (trademark.requestDate | date : 'dd-MMM-yyyy')
                : null
            }};
            {{
              trademark?.releaseNumber
                ? 'registration: ' + trademark.releaseNumber + ', ' + (trademark.releaseDate | date : 'dd-MMM-yyyy')
                : null
            }}
          </span>
        </mat-option>
      </ng-container>
      <mat-option *ngIf="filteredIpRights?.length === 0" disabled class="text-red-600"
        >No matching records were found
      </mat-option>
    </ng-template>
  </mat-autocomplete>
  <ng-content></ng-content>
</mat-form-field>
