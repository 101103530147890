import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { INormalizedIpRight } from '@ipnote/interface';
import { OIPType } from '@ipnote/enum';
import { SearchByTrademarkService } from '../../services/search-by-trademark-service/search-by-trademark.service';
import { DialogService } from '../../services/dialogs/dialog.service';
import { SearchByPatentService } from '../../services/search-by-patent-service/search-by-patent.service';

@UntilDestroy()
@Component({
  selector: 'app-search-ip-right-control',
  templateUrl: './search-ip-right-control.component.html',
  styleUrls: ['./search-ip-right-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SearchIpRightControlComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SearchIpRightControlComponent,
    },
  ],
})
export class SearchIpRightControlComponent implements ControlValueAccessor, OnChanges, Validator {
  private previousIpRightNumber: string | null = null;
  private hasIpRightNumberSubscription = false;
  inputChanged = false;
  ipRightNumber = new FormControl();
  isLoading = false;
  filteredIpRights: INormalizedIpRight[];
  searchIpRightLength = 4;
  selectedTrademark: INormalizedIpRight;
  touched = false;
  disabled = false;

  @Input() label: string;
  @Input() required = false;
  @Input() autoCompletePanelWidth: number;
  @Input() country: string;
  @Input() oipType: OIPType;

  @Output() selected = new EventEmitter<{ data: INormalizedIpRight; index: number }>();
  @Output() applicationNumber = new EventEmitter<number>();

  onChange = (value: string) => {};
  onTouched = () => {};

  constructor(
    private trademarkService: SearchByTrademarkService,
    private patentService: SearchByPatentService,
    private dialogs: DialogService,
  ) {}

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    const value = this.ipRightNumber.value;
    if (!value && this.required) {
      this.ipRightNumber.setValidators(Validators.required);
      return {
        required: true,
      };
    }
  }

  writeValue(value: string) {
    this.ipRightNumber.setValue(value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    disabled ? this.ipRightNumber.disable() : this.ipRightNumber.enable();
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  searchIpRight(data) {
    const service =
      this.oipType === OIPType.TRADEMARK
        ? this.trademarkService.searchByNumber(data, this.country)
        : this.patentService.searchByNumber(data);

    return service.pipe(
      catchError((err) => {
        return of([]);
      }),
      finalize(() => {
        this.isLoading = false;
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.country?.currentValue !== changes?.country?.previousValue) {
      this.inputChanged = true;
      if (this.ipRightNumber.value >= this.searchIpRightLength) {
        this.isLoading = true;
      }
    }
    if (changes?.oipType?.currentValue !== changes?.oipType?.previousValue) {
      this.inputChanged = true;
    }
  }

  onSelect(trademark: INormalizedIpRight, i: number) {
    this.selectedTrademark = trademark;
    if (trademark) {
      this.selected.emit({ data: trademark, index: i });
    }
  }

  onIpRightNumberInputFocus(): void {
    if (this.hasIpRightNumberSubscription) return;
    this.hasIpRightNumberSubscription = true;
    this.addIpRightNumberSubscription();
    this.ipRightNumber.setValue(this.ipRightNumber.value);
  }

  addIpRightNumberSubscription(): void {
    this.ipRightNumber.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter((res) => {
          if (typeof res === 'object') {
            if (!res) this.clearPreviousIpRightSearch();
            return false;
          }

          const trimmedIpRightNumber = res.toString().trim();

          if (!this.selectedTrademark) {
            this.applicationNumber.next(trimmedIpRightNumber);
          }

          const isInvalidIpRightNumber = trimmedIpRightNumber.length < this.searchIpRightLength;

          if (isInvalidIpRightNumber) {
            this.clearPreviousIpRightSearch();
            return false;
          }

          const isWrongOipType = ![OIPType.TRADEMARK, OIPType.INVENTION].includes(this.oipType);
          const isDuplicateIpRightNumber = trimmedIpRightNumber === this.previousIpRightNumber;

          if (isWrongOipType || isDuplicateIpRightNumber) return false;

          this.previousIpRightNumber = trimmedIpRightNumber;
          return true;
        }),
        tap(() => (this.isLoading = true)),
        debounceTime(1000),
        switchMap((data) => this.searchIpRight(data).pipe(finalize(() => (this.isLoading = false)))),
      )
      .subscribe({
        next: (res) => {
          this.filteredIpRights = res;
          this.inputChanged = false;
        },
        error: (err) => this.dialogs.error(err),
      });
  }

  clearPreviousIpRightSearch(): void {
    this.previousIpRightNumber = null;
    this.filteredIpRights = undefined;
  }
}
