<div class="sm:w-[500px] wrapper">
  <div class="title">
    <h2 mat-dialog-title>{{'feedback_processing_title' | transloco }}</h2>
  </div>
  <button class="button-close" [mat-dialog-close]="false" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content class="break-words">{{'feedback_processing' | transloco }}</mat-dialog-content>
  <mat-dialog-actions class="pt-6">
    <button mat-raised-button color="primary" (click)="close()">
      {{'ok' | transloco}}
    </button>
  </mat-dialog-actions>
</div>
