import { Component, EventEmitter, Input, Output } from '@angular/core';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icFolder from '@iconify/icons-ic/baseline-folder';
import icFile from '@iconify/icons-ic/outline-insert-drive-file';
import icResize from '@iconify/icons-mdi/resize';
import { filter, map, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '../../services/dialogs/dialog.service';

import { ShowFileService } from '../../../page-modules/desk/services/show-file/show-file.service';
import { keyBy, reverse, sortBy, without } from 'lodash';
import { FileType } from '../../dataset/FileType';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { FileManagerService } from '../../services/file-manager/file-manager.service';
import { TranslocoService } from '@ngneat/transloco';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DownloadService } from '../../services/download/download.service';
import { DateFormatService } from '../../../../@vex/services/date-format.service';
import { FilesService } from './files.service';
import { fileOrders, IFileEntity, IFileSortSelect, IFolderFileEntity } from '@ipnote/interface';
import { IPrivateFiles } from './file.interface';
import { FileTplType } from '@ipnote/type';
import { FilePreviewService } from '../../services/file-preview/file-preview.service';

@UntilDestroy()
@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent {
  icEdit = icEdit;
  icDelete = icDelete;
  icFolder = icFolder;
  icFile = icFile;
  icResize = icResize;

  @Input() readOnly: boolean;
  @Input() privateFiles: IPrivateFiles = null;
  @Input() haveTags = false;
  @Input() uploadFolder = false;

  public $filesTpl: BehaviorSubject<FileTplType[]> = new BehaviorSubject<FileTplType[]>([]);

  // tslint:disable-next-line:variable-name
  private _files: FileTplType[] = [];
  private filesLoading: IFileEntity[] = [];

  @Input()
  set files(f: FileTplType[]) {
    this._files = f;
    this.$filesTpl.next(this.sortedFiles(f));
  }

  get files(): FileTplType[] {
    return this._files;
  }

  public fileOrder: IFileSortSelect = fileOrders[0];

  fileTypesMap: { [name: string]: FileType } = {};

  // tslint:disable-next-line:variable-name
  _fileTypes: FileType[] = null;
  @Input() set fileTypes(fileTypes: FileType[]) {
    this._fileTypes = fileTypes;
    this.fileTypesMap = keyBy(fileTypes, (g) => g.value);
  }

  get fileTypes(): FileType[] {
    return this._fileTypes;
  }

  @Output() foldersChange = new EventEmitter<FileTplType[]>();
  folderToUpload: any;

  constructor(
    private dialogs: DialogService,
    private translocoService: TranslocoService,
    private showFileService: ShowFileService,
    private uploadService: FileManagerService,
    private downloadService: DownloadService,
    public dateFormatService: DateFormatService,
    private fileService: FilesService,
    private filePreviewService: FilePreviewService,
  ) {}

  fileLoading(f: IFileEntity): boolean {
    return !!this.filesLoading.find((file) => file.id === f.id);
  }

  /***
   * Файл загружен
   * param items
   */
  handleFile(items: IFileEntity[] | IFolderFileEntity): void {
    this.files = this.files.concat(items);
    this.foldersChange.emit(this._files);
  }

  /***
   * Поиск/фильтрация файлов
   * param inputValue
   */
  public filesSearch(inputValue: string): void {
    this.$filesTpl.next(
      this._files.filter((val) => {
        return val.name.toLowerCase().includes(inputValue.toLowerCase());
      }),
    );
  }

  /***
   * Сортировка
   * param sort
   */
  public changeSortFiles(sort: IFileSortSelect): void {
    this.fileOrder = sort;
    this.$filesTpl.next(this.sortedFiles(this._files));
  }

  private sortedFiles(files: FileTplType[]): FileTplType[] {
    const sortedFiles = sortBy(files, [(o) => o[this.fileOrder.field]]);
    return this.fileOrder.order === 'ASC' ? reverse(sortedFiles) : sortedFiles;
  }

  /***
   * Переименование файла
   * param file
   */
  renameFile(file: IFileEntity | IFolderFileEntity, isFolder = false): void {
    this.dialogs
      .rename({ label: this.translocoService.translate('file-name'), value: file.name })
      .pipe(
        filter((p) => !!p),
        untilDestroyed(this),
        switchMap((newName) =>
          !isFolder
            ? this.fileService.updateFile(file.id, { name: newName })
            : this.fileService.updateFolderFiles(file.id, { name: newName }),
        ),
      )
      .subscribe((res) => {
        file.name = res.name;
        this.foldersChange.emit(this._files);
      });
  }

  /** *
   * Удаление файла
   * param file
   */
  removeFile(file: IFileEntity | IFolderFileEntity, isFolder = false, folderId?: number): void {
    this.confirmDeletion(this.translocoService.translate('file'), file.name)
      .pipe(
        filter(Boolean),
        untilDestroyed(this),
        switchMap((newName) =>
          !isFolder ? this.fileService.deleteFile(file.id) : this.fileService.deleteFolderFiles(file.id),
        ),
      )
      .subscribe(() => {
        if (typeof folderId === 'number') {
          const indexFolder = this._files.findIndex((f) => f.id === folderId);
          // @ts-ignore
          this._files[indexFolder].files = without(this._files[indexFolder].files, file);
          this.files = this._files;
        } else {
          this.files = without(this._files, file);
        }

        this.foldersChange.emit(this._files);
      });
  }

  /** *
   *  Подтверждение удаления
   * @param type
   * @param name
   * @private
   */
  private confirmDeletion(type: string, name: string): Observable<boolean> {
    return this.dialogs.confirm({
      title: this.translocoService.translate('deletion'),
      message: this.translocoService.translate('do_you_really_want_to_delete', { name, type }),
      primaryButton: this.translocoService.translate('yes_accept'),
      slaveButton: this.translocoService.translate('no'),
    });
  }

  /** *
   * Просмотр файла
   * param file
   */
  showFile(file: IFileEntity): void {
    if (window.innerWidth < 768) {
      return;
    }

    if (this.filePreviewService.openFile(file)) {
      return;
    }
    this.downloadFile(file);
  }
  downloadFile(file: IFileEntity) {
    this.filesLoading.push(file);
    // @ts-ignore
    this.downloadService.getDownloadFileS3(file.url, file.name).subscribe({
      next: (value) => (this.filesLoading = this.filesLoading.filter((f) => f !== file)),
      error: (err) => (this.filesLoading = this.filesLoading.filter((f) => f !== file)),
    });
  }

  /** *
   * Просмотр комментария
   * @param comment
   */
  showComment(e: Event, comment: string): void {
    e.stopPropagation();
    e.preventDefault();
    this.dialogs.alert({ title: 'Comment', message: comment, primaryButton: 'Close' });
  }

  processFileToFolder($event: Event): void {
    // tslint:disable-next-line:deprecation
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    const files = Array.from(target.files);
    target.value = '';
    const uploads = files.map((f) =>
      this.uploadService.upload(f).pipe(
        map((url) => ({
          url,
          name: f.name,
          type: f.type,
          size: f.size,
          createdAt: new Date(),
          fileItemType: 'file',
        })),
      ),
    );
    combineLatest(uploads)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.folderToUpload.files.push(...data);
        this.foldersChange.emit(this._files);
        this.folderToUpload = null;
      });
  }

  drop($event: CdkDragDrop<any[]>): void {
    moveItemInArray(this._files, $event.previousIndex, $event.currentIndex);
    this.files = this._files;
    this.foldersChange.emit(this._files);
  }

  getFileComment(comment: string) {
    const newComment = comment.length > 30 ? comment.slice(0, 30) + '...' : comment;
    return newComment.replace(/<br>/gi, ' ');
  }
}
