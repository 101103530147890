import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { DialogService } from '../../services/dialogs/dialog.service';
import { TranslocoService } from '@ngneat/transloco';
import icAccount from '@iconify/icons-ic/baseline-account-circle';
import icLogout from '@iconify/icons-ic/baseline-keyboard-tab';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { UserSignOut } from '../../../store/actions/user.actions';
import { Observable } from 'rxjs';
import { selectStateUserInfo } from '#selectors';
import { IUserSignInRes } from '@ipnote/interface';
import { clearTrademarkSearchResult } from 'app/store/actions/trademark-search.actions';

@Component({
  selector: 'app-user-button',
  templateUrl: './user-button.component.html',
  styleUrls: ['./user-button.component.scss'],
})
export class UserButtonComponent {
  public userStore$: Observable<IUserSignInRes> = this.store.select(selectStateUserInfo);
  @Input() marketPlace;
  icons = {
    icAccount,
    icLogout,
  };
  @Input() companyId: number | string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialogService: DialogService,
    private translocoService: TranslocoService,
    private store: Store<AppState>,
  ) {}

  async logout(): Promise<void> {
    const res = await this.dialogService
      .confirm({
        title: 'Log out',
        message: this.translocoService.translate('Are you sure you want to log out?'),
        primaryButton: this.translocoService.translate('Log out'),
        slaveButton: this.translocoService.translate('cancel'),
      })
      .toPromise();
    if (res) {
      this.store.dispatch(clearTrademarkSearchResult());
      this.store.dispatch(UserSignOut());
    }
  }
}
