import { Component, Input } from '@angular/core';
import { EnumsService } from '../../../../page-modules/desk/services/enums/enums.service';
import icCloseCircle from '@iconify/icons-ant-design/close-circle-filled';
import { FilesService } from '../files.service';
import { BehaviorSubject } from 'rxjs';
import { delay, finalize, tap } from 'rxjs/operators';
import { FileTagsFolderTypes, FileTagsTypes, FolderTypes } from '@ipnote/type';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-file-tags',
  templateUrl: './file-tags.component.html',
  styleUrls: ['./file-tags.component.scss'],
})
export class FileTagsComponent {
  icCloseCircle = icCloseCircle;
  folderTypes = FolderTypes;
  thisTypes: FileTagsFolderTypes;
  fileTypes = this.enums.folderTypes;
  $isUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() fileTags: [FileTagsFolderTypes, FileTagsTypes] | null;
  @Input() fileId: number;
  @Input() isFolder = false;
  @Input() readOnly = false;

  get folder(): FileTagsFolderTypes | undefined | null {
    return this.fileTags ? this.fileTags[0] : undefined;
  }

  get tag(): FileTagsTypes | undefined | null {
    return this.fileTags ? this.fileTags[1] : undefined;
  }

  constructor(
    private enums: EnumsService,
    private fileService: FilesService,
    private translocoService: TranslocoService,
  ) {}

  deleteTag($e) {
    this.prevent($e);
    this.fileTags = null;
  }

  selectFileTags(type: string, subType: FileTagsTypes, e?: Event) {
    const update = !this.isFolder
      ? this.fileService.updateFile(this.fileId, { tags: [type, subType] })
      : this.fileService.updateFolderFiles(this.fileId, { tags: [type, subType] });

    update
      .pipe(
        tap(() => this.$isUpdate.next(true)),
        delay(700), // @TODO: чисто визуально отобразить работу :)
        finalize(() => this.$isUpdate.next(false)),
      )
      .subscribe((res) => {
        this.thisTypes = res.tags[0];
        this.fileTags = res.tags;
      });
  }

  prevent($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
  }

  getTooltip(folder: string, tag: string) {
    return `${this.translocoService.translate(folder + '_docs')}: ${this.translocoService.translate(tag)}`;
  }
}
