import { FileManagerService } from '../../../../../app-common/services/file-manager/file-manager.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { FeedbackService } from '../../../services/feedback/feedback.service';
import { ITaskEntity } from '@ipnote/interface';
import { TaskFlowService } from '../../../services/task-flow/task-flow.service';
import { DialogService } from '../../../../../app-common/services/dialogs/dialog.service';

@Component({
  selector: 'app-accept-task',
  templateUrl: './accept-task.component.html',
  styleUrls: ['./accept-task.component.scss'],
})
export class AcceptTaskComponent {
  file: File[] = [];
  userRatingArr: number[] = [1, 2, 3, 4, 5];
  userRating: number;
  // act: File;
  form = this.fb.group({
    text: [null],
  });

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { task: ITaskEntity; onlyFeedBackMode: boolean },
    private uploadService: FileManagerService,
    private dialogRef: MatDialogRef<AcceptTaskComponent>,
    private feedbackService: FeedbackService,
    private taskFlowService: TaskFlowService,
    private dialogs: DialogService,
  ) {}

  handleFile(event: any, field: string): void {
    if (event.target.files && event.target.files.length) {
      if (field === 'file') this.file.push(...event.target.files);
      /*if(field==='act')
        this.act = event.target.files[0];*/
    }
    event.target.value = '';
  }

  deleteFile(index: number, isAct: boolean): void {
    /*if(isAct)
      delete this.act
    else*/
    this.file.splice(index, 1);
  }

  close(): void {
    this.dialogRef.close(null);
  }

  async send(): Promise<any> {
    //const files = [];
    // let act;
    // if (this.file.length) {
    //   await Promise.all(
    //     this.file.map(async (f: File) => {
    //       const url = await this.uploadService.upload(f).toPromise();
    //
    //       files.push({
    //         url,
    //         fileItemType: 'file',
    //         name: f.name,
    //         type: f.type,
    //         size: f.size,
    //         createdAt: new Date()
    //       });
    //     })
    //   );
    // }
    /*if(this.act){
      const url = await this.uploadService.file-manager(this.act).toPromise();
      act = {
        url,
        fileItemType: 'act',
        name: this.act.name,
        type: this.act.type,
        size: this.act.size,
        createdAt: new Date()
      }
    }*/

    const { text } = this.form.value;
    // if (this.data.onlyFeedBackMode) {
    //   this.feedbackService.postFeedback({
    //     text,
    //     task: this.data.task,
    //     rating: this.userRating,
    //     executorCompany: this.data.task.serviceRequests[0].executorCompany,
    //     clientCompany: this.data.task.company
    //   }).subscribe(result => this.dialogRef.close(result));
    // } else {
    //   const res = await this.tasksService.accept(this.data.task.id, {
    //     text,
    //     rating: this.userRating
    //   })
    //     .toPromise();
    //   this.dialogRef.close(res);
    // }
    this.dialogRef.close({ userRating: this.userRating, text });
  }

  setUserRating(rating: number) {
    if (this.userRating && rating === this.userRating) {
      this.userRating = null;
    } else {
      this.userRating = rating;
    }
  }
}
