/** *
 * OLD chat
 */
export enum AiChatRequestCommandEnum {
  INIT = 'INIT',
  START = 'START',
  DISCUSSION = 'DISCUSSION',
  GET_CHAT = 'GET_CHAT',
  UPLOAD_FILE = 'UPLOAD_FILE',
  COMPLETE = 'COMPLETE',
  CHECK_PAYOUT = 'CHECK_PAYOUT',
  CHECK_REGISTRATION = 'CHECK_REGISTRATION',
  ADD_RATING = 'ADD_RATING',
}

export enum AiChatResponseCommandEnum {
  WELCOME = 'WELCOME',
  CHAT_MESSAGES = 'CHAT_MESSAGES',
  STREAM_TOKEN = 'STREAM_TOKEN',
  END_TOKEN = 'END_TOKEN',
  NEW_MESSAGE = 'NEW_MESSAGE',
  CREATED_TASK = 'CREATED_TASK',
  WAITING = 'WAITING',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  WAITING_REGISTRATION = 'WAITING_REGISTRATION',
  EVENT = 'EVENT',
  RATING_ADDED = 'RATING_ADDED',
  RELOAD_PAGE = 'RELOAD PAGE',
  RETURN_BTN = 'RETURN_BTN',
  ERROR = 'ERROR',
}

export enum AiChatRequestRegistrationCommandEnum {
  INIT_NEW = 'INIT_NEW',
  INIT_ACTION = 'INIT_ACTION',
  START = 'START',
  DISCUSSION = 'DISCUSSION',
}

export enum AiChatResponseRegistrationCommandEnum {
  WELCOME = 'WELCOME',
  STREAM_TOKEN = 'STREAM_TOKEN',
  END_TOKEN = 'END_TOKEN',
  NEW_MESSAGE = 'NEW_MESSAGE',
  WAITING = 'WAITING',
  WAITING_CODE = 'WAITING_CODE',
  AUTH_DATA = 'AUTH_DATA',
  EVENT = 'EVENT',
  RELOAD_PAGE = 'RELOAD PAGE',
}

/** *
 * OLD
 */

export enum AiDiscussionCommandEnum {
  START = 'START',
  END = 'END',
  ERROR = 'ERROR',
  NEW_TOKEN = 'NEW_TOKEN',
  WAITING = 'WAITING',
  COMPLETE_TOOL = 'COMPLETE_TOOL',
  CREATED_TASK = 'CREATED_TASK',
  WAITING_REGISTRATION = 'WAITING_REGISTRATION',
  RETURN_BTN = 'RETURN_BTN',
}

export enum AiWidgetRequestCommandEnum {
  OPEN = 'OPEN',
  SEND_MESSAGE = 'SEND_MESSAGE',
  UPLOAD_FILE = 'UPLOAD_FILE',
  UPLOAD_AUDIO_MESSAGE = 'UPLOAD_AUDIO_MESSAGE',
  CHECK_PAYOUT = 'CHECK_PAYOUT',
  ADD_RATING = 'ADD_RATING',
  PREVIOUS_MESSAGE = 'PREVIOUS_MESSAGE',
  STOP = 'STOP',
}
