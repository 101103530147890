import { IEnvironment } from '../app/app-common/dataset/Environment.interface';
import packageJson from '../../../../package.json';

export const environment: IEnvironment = {
  version: packageJson.version,
  production: false,
  hostUrl: '/',
  baseUrl: 'https://develop.api.ipnote.tech',
  appUrl: 'https://develop.app.ipnote.tech',
  filesServerBaseUrl: 'https://develop.api.ipnote.tech/file-manager',
  authBaseUrl: 'https://develop.api.ipnote.tech',
  chatsBaseUrl: 'https://develop.api.ipnote.tech',
  contentBaseUrl: 'https://content.ipnote.tech',
  termsAndServiceUrl: 'https://content.ipnote.tech/manifest.json',
  baseDomain: 'ipnote.tech',
  invoiceTemplate: '5ec618d1acdc900010e82031',
  environment: 'develop',
  i18nUrl: 'https://content.ipnote.pro/i18n/{lang}.json',
  googleTag: false,
  profitwell: false,
  sentry: 'https://c33b91d54e764b77b80633eb9d356297@o1114337.ingest.sentry.io/6145797',
  posthog: {
    enable: false,
  },
  intercom: {
    enable: false,
    apiBase: 'https://api-iam.intercom.io',
    appId: 'di6xd7xf',
  },
  stripe: {
    stripeKey:
      'pk_test_51IqsGwHQkctxdv6IVlWeoqfW8JFAptkthue4L5V7nsPy7a8jG2vQelDTifXoYA91PwxGvemu1VDfh1sEKZ99eGlx00S4e2UvQy',
    pricingTableTrademarkSearch: 'prctbl_1OwMzsHQkctxdv6IXWlzlUXl',
    pricingTablePrimeCustomer: 'prctbl_1OwMAIHQkctxdv6IYYFcwxpA',
    pricingTablePrimeProvider: 'prctbl_1OwT0cHQkctxdv6I9wqz8Q5R',
  },
  telegramBot: 'https://t.me/devIpnoteBot',
};
