import { IAgentStateChannels } from '../interface/agent-state-channels.interface';
import { PromptVariable } from '../interface/ai-chat-variable.interface';

export const SOCKET_NAME_AI_CHAT = 'ai-chat';
export const SOCKET_NAME_AI_WIDGET = 'ai-widget';
export const NAME_AI_PARAMS = 'actions';

export const PROMPT_VARIABLES: PromptVariable[] = [
  {
    key: 'currentDate',
    description: 'Current date in YYYY-MM-DD format',
    getValue: () => new Date().toISOString().split('T')[0],
  },
  {
    key: 'companyName',
    description: 'User company name',
    getValue: (state: IAgentStateChannels) => state?.temp?.companyInfo?.name,
  },
  {
    key: 'userLanguage',
    description: 'User language used for communication',
    // @ts-ignore
    getValue: (state: IAgentStateChannels) => state?.temp?.userInfo.settings?.browserLang,
  },
];

export const AUDIO_TRANSCRIPTION_EMPTY = 'audio transcription is empty';
