<ng-container *ngIf="!isAdaptive">
  <img
    [matTooltip]="company.name" matTooltipPosition="above"
    [ngStyle]="{'height': size+'px', 'width': size+'px', 'margin-left': i>0 ? size/-4+'px': ''}"
    [ngClass]="{'blur-sm': company.name.includes('**')}"
    class="shrink-0 bg-designSystem-boysenberry-shadow border-2 border-designSystem-iceberg rounded-full object-cover cursor-pointer"
    *ngFor="let company of displayedCompanies; index as i" [src]="company.logo" alt=""
    onerror="this.src='assets/svg/company.svg'"
  >
  <div
    *ngIf="companies.length>countOfDisplayedCompanies"
    [ngStyle]="{'height': size+'px', 'width': size+'px', 'margin-left': size/-4+'px'}"
    class="
      shrink-0 leading-none rounded-full border-2 border-designSystem-iceberg bg-designSystem-iceberg
      text-xs text-designSystem-dark-spell flex items-center justify-center font-medium
    "
  >+{{companies.length-countOfDisplayedCompanies}}</div>
</ng-container>

<ng-container *ngIf="isAdaptive">
  <img
    [matTooltip]="company.name" matTooltipPosition="above"
    [ngClass]="{'blur-sm': company.name.includes('**')}"
    [ngStyle]="{'margin-left': i>0 ? 24/-4+'px': ''}"
    class="
      shrink-0 bg-designSystem-boysenberry-shadow border-2 border-designSystem-iceberg rounded-full object-cover
      cursor-pointer w-6 h-6 xl:w-8 xl:h-8
    "
    *ngFor="let company of displayedCompanies; index as i" [src]="company.logo" alt=""
    onerror="this.src='assets/svg/company.svg'"
  >
  <div
    *ngIf="companies.length>countOfDisplayedCompanies"
    [ngStyle]="{'margin-left': 24/-4+'px'}"
    class="
      shrink-0 leading-none rounded-full border-2 border-designSystem-iceberg bg-designSystem-iceberg
      text-xs text-designSystem-dark-spell flex items-center justify-center font-medium w-6 h-6 xl:w-8 xl:h-8
    "
  >+{{companies.length-countOfDisplayedCompanies}}</div>
</ng-container>
