import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableColumn } from '../../../../@vex/interfaces/table-column.interface';
import { chain, sortBy } from 'lodash';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

interface Fields {
  label: string;
  property: string | symbol | number;
  isChecked: boolean;
}

@Component({
  selector: 'app-settings-sidenav',
  templateUrl: './settings-sidenav.component.html',
  styleUrls: ['./settings-sidenav.component.scss'],
})
export class SettingsSidenavComponent implements OnInit {
  @Input() columns;
  @Output() columnsChange: EventEmitter<TableColumn<any>[]> = new EventEmitter<TableColumn<any>[]>();

  dirty = false;

  hidden: string[] = [];
  fixedFields: Fields[] = [];
  fields: Fields[] = [];

  @Input() prefix: string;

  constructor() {}

  close() {
    this.columnsChange.emit(this.columns);
  }

  ngOnInit() {
    this.hidden = JSON.parse(localStorage.getItem(`${this.prefix}-hidden-columns`)) || [];
    const order: string[] = JSON.parse(localStorage.getItem(`${this.prefix}-hidden-order`)) || [];
    this.fixedFields = this.columns
      .filter((field) => field.property === 'indicator')
      .map(({ label, property }) => ({
        label,
        property,
        isChecked: !this.hidden.includes(String(property)),
      }));
    this.fields = chain(this.columns)
      .filter((c) => !['checkbox', 'button'].includes(c.type) && c.property !== 'indicator')
      .map(({ label, property }) => ({ label, property, isChecked: !this.hidden.includes(String(property)) }))
      .sortBy((c) => {
        const index = order.indexOf(String(c.property));
        if (index === -1) {
          return order.length;
        }
        return index;
      })
      .value();
  }

  save() {
    const order: string[] = this.fields.map((f) => String(f.property));
    localStorage.setItem(`${this.prefix}-hidden-columns`, JSON.stringify(this.hidden));
    localStorage.setItem(`${this.prefix}-hidden-order`, JSON.stringify(order));

    this.columns = sortBy(this.columns, (c) => {
      switch (c.type) {
        case 'checkbox':
          return -2;
        case 'button':
          return 9999;
        default:
          return order.indexOf(String(c.property));
      }
    });
    this.columns.forEach((c) => (c.visible = !this.hidden.includes(c.property as string)));
    this.columnsChange.emit(this.columns);

    this.dirty = false;
  }

  isHidden(property: string): boolean {
    return this.hidden.includes(property);
  }

  toggleVisibility(field: Fields) {
    this.dirty = true;
    const property = String(field.property);

    if (this.isHidden(property)) {
      this.hidden.splice(this.hidden.indexOf(property), 1);
    } else {
      this.hidden.push(property);
    }

    field.isChecked = !field.isChecked;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.fields, event.previousIndex, event.currentIndex);
    this.dirty = true;
  }
}
