import { ChangeDetectorRef, Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { addDays } from 'date-fns';
import { EnumsService } from '../../../desk/services/enums/enums.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';
import { TwoFactorVerificationService } from './two-factor-verification.service';
import { VerificationCodePurpose, VerificationCodeType } from '@ipnote/enum';
import { DialogService } from '../../../../app-common/services/dialogs/dialog.service';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { selectStateSelectedCompany } from '#selectors';
import { ICompanyEntity, IUserSignInRes } from '@ipnote/interface';
import { ActivatedRoute } from '@angular/router';
import {
  UserGetInfo,
  UserProfileUpdate,
  UserSetBackUrl,
  UserSetData,
  UserSetNewRegistration,
  UserSignOut,
} from '../../../../store/actions/user.actions';
import { selectStateUser } from '../../../../store/selectors/user.selectors';
import { StateUser } from '../../../../store/reducers/user';
import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';

export enum TwoFactorSteps {
  SET_UP_TWO_FACTOR = 'set_up_two_factor',
  VERIFICATION = 'verification',
}

@UntilDestroy()
@Component({
  selector: 'app-set-up-two-factor',
  templateUrl: './set-up-two-factor.component.html',
  styleUrls: ['./set-up-two-factor.component.scss'],
  animations: [fadeInUp400ms],
})
export class SetUpTwoFactorComponent implements OnInit, DoCheck {
  loading = false;
  VerificationCodeType = VerificationCodeType;
  seconds: number;
  minutes: number;
  interval;
  public selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  selectedCompany: ICompanyEntity;
  // SmsSupportedCountries = SmsSupportedCountries
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
  });

  userState$: Observable<StateUser> = this.store.select(selectStateUser);
  userState: StateUser;

  readonly TwoFactorSteps = TwoFactorSteps;
  verificationCode = new FormControl(null, [Validators.required, Validators.max(4)]);
  step: TwoFactorSteps = TwoFactorSteps.VERIFICATION;
  codeType = VerificationCodeType.FLASH_CALL;
  purpose = VerificationCodePurpose.PHONE_CONFIRMATION;
  @ViewChild('myInput') myInput: ElementRef<HTMLInputElement>;
  ALLOWED_SKIP_TWO_FA_TIMES = 3;
  SKIP_TWO_FA_DAYS = 2;

  get isTimerOn(): boolean {
    return this.minutes + this.seconds > 0;
  }

  constructor(
    private dialogs: DialogService,
    public enums: EnumsService,
    private twoFactorVerification: TwoFactorVerificationService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {
    this.userState$.pipe(take(1), untilDestroyed(this)).subscribe((userState) => {
      this.userState = userState;
      if (this.userState?.user?.phone?.number && !this.userState.user.phoneConfirmedAt) {
        this.step = TwoFactorSteps.VERIFICATION;
        this.setPhoneForm(this.userState.user);
        this.send(VerificationCodeType.SMS);
      } else {
        this.step = TwoFactorSteps.SET_UP_TWO_FACTOR;
      }
    });
    this.selectedCompany$.pipe(untilDestroyed(this)).subscribe((company) => {
      this.selectedCompany = company;
    });
  }

  ngOnInit() {
    this.verificationCode.valueChanges.pipe(filter((code) => code.length === 4)).subscribe({
      next: (value) => {
        this.verification(value).subscribe({
          next: ({ verified }) => {
            if (verified) {
              this.redirect();
            } else {
              this.verificationCode.setErrors({ incorrect: true });
              this.verificationCode.markAllAsTouched();
            }
          },
          error: (err) => this.dialogs.error(err),
        });
      },
    });
  }

  setStep(step: TwoFactorSteps) {
    this.step = step;
  }

  ngDoCheck() {
    this.myInput?.nativeElement?.focus();
  }

  setPhoneForm(user: IUserSignInRes) {
    this.phoneForm.controls.phone.setValue({
      countryCode: user.phone.countryCode,
      dialCode: user.phone.dialCode,
      e164Number: user.phone.dialCode + user.phone.number,
      number: user.phone.dialCode + user.phone.number,
    });
  }

  startTimer() {
    this.seconds = 29;
    this.minutes = 0;
    clearInterval(this.interval);
    this.interval = setInterval(this.updateTime.bind(this), 1000);
  }

  updateTime() {
    if (this.seconds > 0) {
      this.seconds--;
      if (this.seconds === 0) {
        if (this.minutes > 0) {
          this.minutes--;
          this.seconds = 60;
        }
      }
    }

    if (this.seconds === 0 && this.minutes === 0) {
      clearInterval(this.interval);
    }
  }

  send(codeType: VerificationCodeType) {
    this.loading = true;
    this.codeType = codeType;
    const { dialCode, countryCode, e164Number } = this.phoneForm.controls.phone.value;
    this.twoFactorVerification
      .sendCode({
        phone: { number: e164Number.replace(dialCode, ''), dialCode: dialCode, countryCode: countryCode },
        type: this.codeType,
        purpose: this.purpose,
      })
      .pipe()
      .subscribe({
        next: (res) => {
          this.step = TwoFactorSteps.VERIFICATION;
          setTimeout(() => {
            console.log('my input', this.myInput);
          }, 1000);

          this.cdr.detectChanges();
          this.startTimer();
          this.loading = false;
        },
        error: (err) => {
          this.dialogs.error(err);
          this.loading = false;
        },
      });
  }

  verification(code: string) {
    return this.twoFactorVerification.verifyCode({
      type: this.codeType,
      purpose: this.purpose,
      code: code,
    });
  }

  logOut() {
    this.store.dispatch(UserSignOut());
  }

  showSkipTwoFABlock(): boolean {
    return (
      this.userState.user.emailConfirmedAt && this.userState.user.twoFASkippedTimes < this.ALLOWED_SKIP_TWO_FA_TIMES
    );
  }

  skipTwoFA() {
    const user = { ...this.userState.user };

    user.skipTwoFAUntil = addDays(new Date(), this.SKIP_TWO_FA_DAYS);
    user.twoFASkippedTimes++;

    this.store.dispatch(
      UserProfileUpdate({
        payload: {
          ...user,
        },
      }),
    );

    this.store.dispatch(UserSetData({ user }));

    setTimeout(this.redirect.bind(this), 500);
  }

  redirect() {
    if (this.userState?.returnLink) {
      const url = this.userState.returnLink;
      this.store.dispatch(UserSetBackUrl({ url: null }));
      window.location.replace(url);
    } else {
      window.location.replace('desk/default');
    }
  }
}
