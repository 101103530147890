import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  chatMessagesReceived,
  closeChatWindow,
  endTokenReceived,
  eventReceived,
  initializeChat,
  newMessageReceived,
  ratingAdded,
  reloadPageReceived,
  returnButtonReceived,
  sendCommand,
  stopResponse,
  streamTokenReceived,
  taskCreatedReceived,
  toggleChatWindow,
  waitingPaymentReceived,
  waitingReceived,
  welcomeMessageReceived,
} from '../actions/ai-widget.actions';
import { concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { SocketIoService } from '../../app-common/services/socket.io/socket.io.service';
import { SOCKET_NAME_AI_WIDGET } from '@ipnote/const';
import { AiRequestWidgetType, AiResponseType } from '@ipnote/type';
import { AiChatResponseCommandEnum, AiWidgetRequestCommandEnum } from '@ipnote/enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { selectStateAiWidgetChat } from '#selectors';
import { Observable, of } from 'rxjs';
import { StateUser } from '../reducers/user';
import { selectStateUser } from '../selectors/user.selectors';
import { UserSetAiActions, UserSetNewRegistration } from '../actions/user.actions';
import { UserSetCommand } from '../actions/user.actions';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AiWidgetEffects {
  userState$: Observable<StateUser> = this.store.select(selectStateUser);
  private socketInitialized = false;
  private aiActionsMessage: string;

  constructor(private actions$: Actions, private socketService: SocketIoService, private store: Store<AppState>) {
    this.userState$.pipe(untilDestroyed(this)).subscribe((userState: StateUser) => {
      this.aiActionsMessage = userState.aiActions;
    });
  }

  ConnectToChat$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializeChat),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectStateUser)))),
        map(async ([res, user]) => {
          await this.initializeSocket();
          this.socketService.socket.on(SOCKET_NAME_AI_WIDGET, async (res: AiResponseType) => {
            const { command } = res;

            switch (command) {
              case AiChatResponseCommandEnum.WELCOME:
                this.store.dispatch(welcomeMessageReceived(res.message));
                this.sendWelcomeMessage(user);
                this.sendCommandMessage(user);
                break;
              case AiChatResponseCommandEnum.NEW_MESSAGE:
                this.store.dispatch(newMessageReceived(res.message));
                break;
              case AiChatResponseCommandEnum.STREAM_TOKEN:
                this.store.dispatch(streamTokenReceived({ token: res.token }));
                break;
              case AiChatResponseCommandEnum.WAITING:
                this.store.dispatch(waitingReceived({ operation: res.operation }));
                break;
              case AiChatResponseCommandEnum.RETURN_BTN:
                this.store.dispatch(returnButtonReceived({ buttons: res.buttons }));
                break;
              case AiChatResponseCommandEnum.END_TOKEN:
                this.store.dispatch(endTokenReceived());
                break;
              case AiChatResponseCommandEnum.CHAT_MESSAGES:
                this.sendActionsMessage(user);
                this.store.dispatch(chatMessagesReceived({ messages: res.messages, chatId: res.chatId }));
                break;
              case AiChatResponseCommandEnum.WAITING_PAYMENT:
                this.store.dispatch(waitingPaymentReceived({ serviceType: res.serviceType }));
                break;
              case AiChatResponseCommandEnum.CREATED_TASK:
                this.store.dispatch(taskCreatedReceived({ taskId: res.taskId }));
                break;
              case AiChatResponseCommandEnum.EVENT:
                this.store.dispatch(eventReceived({ eventName: res.eventName }));
                break;
              case AiChatResponseCommandEnum.RATING_ADDED:
                this.store.dispatch(ratingAdded({ isGood: res.isGood }));
                break;
              case AiChatResponseCommandEnum.RELOAD_PAGE:
                this.store.dispatch(reloadPageReceived());
                break;
            }
          });
        }),
      ),
    {
      dispatch: false,
    },
  );

  toggleChatWindow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(toggleChatWindow),
        withLatestFrom(
          this.store.select(selectStateAiWidgetChat),
          this.store.select((state) => state.aiWindow.isChatWindowOpen),
        ),
        tap(async ([action, chatState, isChatWindowOpen]) => {
          if (!chatState.initialized) {
            this.store.dispatch(initializeChat());
          }
        }),
        tap(async ([action, chatState, isChatWindowOpen]) => {
          if (isChatWindowOpen) {
            await this.send({
              command: AiWidgetRequestCommandEnum.OPEN,
            });
          } else {
            this.store.dispatch(closeChatWindow());
          }
        }),
      ),
    { dispatch: false },
  );

  SendChatCommand$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendCommand),
        // filter(([_, messages]) => messages.messages.length === 0),
        map(async (action) => {
          await this.send(action);
        }),
      ),
    { dispatch: false },
  );

  stopResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stopResponse),

        map(async (action) => {
          await this.send({
            command: AiWidgetRequestCommandEnum.STOP,
          });
        }),
      ),
    { dispatch: false },
  );

  private async initializeSocket() {
    if (!this.socketInitialized) {
      this.socketService.setupSocketConnection();
      await this.ensureSocketInitialized();
      this.socketInitialized = true;
    }
  }

  private async ensureSocketInitialized() {
    return new Promise<void>((resolve) => {
      const checkSocket = () => {
        if (this.socketService.socket) {
          resolve();
        } else {
          setTimeout(checkSocket, 100);
        }
      };
      checkSocket();
    });
  }

  private async send(data: AiRequestWidgetType) {
    await this.ensureSocketInitialized();
    if (this.socketService.socket) {
      this.socketService.socket.emit(SOCKET_NAME_AI_WIDGET, data);
    } else {
      console.error('Socket is not initialized');
    }
  }

  private sendWelcomeMessage(user: StateUser): void {
    if (user.aiActions) {
      this.store.dispatch(
        sendCommand({
          message: user.aiActions,
          command: AiWidgetRequestCommandEnum.SEND_MESSAGE,
        }),
      );
      this.store.dispatch(UserSetAiActions({ aiActions: '' }));
    }

    this.store.dispatch(UserSetNewRegistration(false));
  }

  private sendActionsMessage(user: StateUser): void {
    if (this.aiActionsMessage && !user.newRegistration) {
      this.store.dispatch(
        sendCommand({
          message: this.aiActionsMessage,
          command: AiWidgetRequestCommandEnum.SEND_MESSAGE,
        }),
      );
    }

    this.store.dispatch(UserSetAiActions({ aiActions: '' }));
    this.aiActionsMessage = '';
  }

  private sendCommandMessage(user: StateUser) {
    if (user.command) {
      this.store.dispatch(
        sendCommand({
          message: user.command,
          command: AiWidgetRequestCommandEnum.SEND_MESSAGE,
        }),
      );

      this.store.dispatch(UserSetCommand({ command: '' }));
    }
  }
}
