import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'ipnote-feedback-processing',
  standalone: true,
  imports: [MatDialogModule, MatIconModule, TranslocoModule, MatButtonModule],
  templateUrl: './feedback-processing.component.html',
  styleUrls: ['./feedback-processing.component.scss'],
})
export class FeedbackProcessingComponent {
  constructor(public dialogRef: MatDialogRef<FeedbackProcessingComponent>) {}

  close(): void {
    this.dialogRef.close(null);
  }
}
