<a *ngIf="isLink(item) && !isFunction(item.route)"
   [fragment]="item.fragment"
   [queryParams]="item.query"
   [routerLinkActiveOptions]="myMatchOptions"
   [routerLink]="item.route"
   class="item"
   id="{{ item.id }}"
   fxLayout="row"
   matRipple
   routerLinkActive="active">
  <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon" fxFlex="none"></mat-icon>
  <div class="flex items-center item-label" fxFlex="auto">{{ item.label }}
    <div *ngIf="item.notification" class="item-notification" fxFlex="none"></div>
  </div>
  <a *ngIf="item.badge"
     [style.background]="'var(' + item.badge.background + ')' | safeStyle"
     [style.color]="'var(' + item.badge.color + ')' | safeStyle"
     class="item-badge hidden sm:block"
     fxFlex="none" [routerLink]="item.badge.action.url" stopPrevent
     [matTooltip]="item.badge.matTooltip" matTooltipPosition="right">{{ item.badge.value }}</a>

  <a
    *ngIf="item.badge"
    [style.background]="'var(' + item.badge.background + ')' | safeStyle"
    [style.color]="'var(' + item.badge.color + ')' | safeStyle"
    class="item-badge block sm:hidden"
    fxFlex="none"
    [routerLink]="item.badge.action.url"
    stopPrevent
  >
    {{ item.badge.value }}
  </a>
</a>

<div (click)="item.route()"
     *ngIf="isLink(item) && isFunction(item.route)"
     class="item"
     fxLayout="row"
     id="{{ item.label }}"
     matRipple
     routerLinkActive="active">
  <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon" fxFlex="none"></mat-icon>
  <span class="item-label" fxFlex="auto">{{ item.label }}</span>
  <span *ngIf="item.badge"
        [style.background]="'var(' + item.badge.background + ')' | safeStyle"
        [style.color]="'var(' + item.badge.color + ')' | safeStyle"
        class="item-badge"
        fxFlex="none">{{ item.badge.value }}</span>
</div>

<ng-container *ngIf="isDropdown(item) && !item.route">
  <div (click)="toggleOpen()"
       [class.active]="isOpen || isActive"
       [class.open]="isOpen"
       class="item"
       fxLayout="row"
       matRipple>
    <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon" fxFlex="none"></mat-icon>
    <span class="item-label" fxFlex="auto">{{ item.label }}</span>
    <span *ngIf="item.badge"
          [style.background]="'var(' + item.badge.background + ')' | safeStyle"
          [style.color]="'var(' + item.badge.color + ')' | safeStyle"
          class="item-badge"
          fxFlex="none">{{ item.badge.value }}</span>
    <mat-icon [icIcon]="icKeyboardArrowRight" class="item-dropdown-icon" fxFlex="none"></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="item-dropdown">
    <vex-sidenav-item (click)="closeSidenav()" *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>

<ng-container *ngIf="isDropdown(item) && item.route">
  <a
    [class.active]="isOpen || isActive"
    [class.open]="isOpen"
    [routerLink]="item.route"
    [routerLinkActiveOptions]="item.routerLinkActive || { exact: false }"
    class="item"
    fxLayout="row"
    routerLinkActive="active"
    matRipple>
    <mat-icon *ngIf="level === 0" [icIcon]="item.icon" class="item-icon" fxFlex="none"></mat-icon>
    <span class="item-label" fxFlex="auto">{{ item.label }}</span>
    <span *ngIf="item.badge"
          [style.background]="'var(' + item.badge.background + ')' | safeStyle"
          [style.color]="'var(' + item.badge.color + ')' | safeStyle"
          class="item-badge"
          fxFlex="none">{{ item.badge.value }}</span>
    <mat-icon [icIcon]="icKeyboardArrowRight" class="item-dropdown-icon" fxFlex="none"></mat-icon>
  </a>
  <div [@dropdown]="isOpen" class="item-dropdown">
    <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1"></vex-sidenav-item>
  </div>
</ng-container>


<ng-container *ngIf="isSubheading(item)">
  <div class="subheading">{{ item.label }}</div>
  <vex-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="0"></vex-sidenav-item>
</ng-container>

