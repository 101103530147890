import { BaseMessage } from '@langchain/core/messages';
import { StateCompanyInfoType, StateUserInfoType } from '../type/agent-state-channels.type';

export interface IAgentState {
  next: string;
  companyId: number | null;
  userId: number | null | string;
  connectionId: string | null;
  chatId: number | null;
  typeChat: 'widget' | 'old';
  [key: string]: any;
}

export interface IAgentStateChannels extends IAgentState {
  messages: BaseMessage[];
  temp?: {
    userInfo?: StateUserInfoType;
    companyInfo?: StateCompanyInfoType;
  };
}
