<div *ngIf="attachments?.length">
  <b>{{ 'attached_documents' | transloco }}:</b>
</div>
<ng-container *ngFor="let attachment of attachments">
  <div class="flex items-center">
    <div
      class="overflow-hidden file relative"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="4px"
      (click)="handleFile(attachment)"
    >
      <mat-icon class="flex-shrink-0 cursor-pointer" color="primary">insert_drive_file</mat-icon>
      <mat-spinner *ngIf="fileLoading(attachment)" class="mat-spinner" [diameter]="20"></mat-spinner>

      <span class="overflow-hidden">
                    <h6 class="truncate file-name">{{ attachment.name }}</h6>
                    <span>{{ attachment.size | filesize }}</span>
                  </span>
      <mat-icon
        (click)="downloadFile(attachment)"
        stopPrevent
        class="!w-[24px] !h-[24px] !text-2xl flex-shrink-0 cursor-pointer"
        color="primary"
      >downloading
      </mat-icon>
    </div>
  </div>
</ng-container>
