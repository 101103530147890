import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FileTagInitValue,
  INormalizedIpRight,
  IOIPEntity,
  IPublicFile,
  ITaskEntity,
  IUserEntity,
} from '@ipnote/interface';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { FileManagerService } from '../../../services/file-manager/file-manager.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '../../../services/dialogs/dialog.service';
import { TranslocoService } from '@ngneat/transloco';
import { EnumsService } from '../../../../page-modules/desk/services/enums/enums.service';
import { OIPService } from '../../../../page-modules/desk/services/oip/oip.service';
import { FolderTypes, InputFolderTypes, OutputFolderTypes } from '@ipnote/type';
import { TasksService } from '../../../../page-modules/desk/services/tasks/tasks.service';
import { FileCategory, FileModels, OIPStage } from '@ipnote/enum';
import { CreateOipDto } from '@ipnote/dto';

@UntilDestroy()
@Component({
  selector: 'app-modal-upload-to-folder',
  templateUrl: './modal-upload-file-from-chat.component.html',
  styleUrls: ['./modal-upload-file-from-chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalUploadFileFromChatComponent implements OnInit {
  file: IPublicFile;
  type = FolderTypes;
  subType: (InputFolderTypes | OutputFolderTypes)[] = Object.values(InputFolderTypes);
  showAddComment = false;
  formFile = new FormGroup({
    type: new FormControl(null, Validators.required),
    subType: new FormControl(null, Validators.required),
    comment: new FormControl(''),
    oips: new FormControl(null, Validators.required),
    oipFilterCtrl: new FormControl(''),
  });
  oipEntityForm = new FormGroup({
    name: new FormControl(null),
    requestNumber: new FormControl(null, Validators.required), // Application number
    requestDate: new FormControl(null, Validators.required), // Filing date
    requestUrl: new FormControl(),
    priorityNumber: new FormControl(),
    priorityDate: new FormControl(),
    priorityUrl: new FormControl(),
    releaseNumber: new FormControl(null, Validators.required), // Document number
    releaseUrl: new FormControl(),
    releaseDate: new FormControl(null, Validators.required),
    expiryDate: new FormControl(),
    prolongationDate: new FormControl(),
  });
  oip: IOIPEntity;
  FileCategory = FileCategory;
  oips: IOIPEntity[];
  filteredOips: Observable<IOIPEntity[]>;
  isOipsLoading = true;
  fileModelId: number;
  isCreateOip: boolean;
  isUpload = false;

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      type: FolderTypes;
      subType: InputFolderTypes;
      addedFile: IPublicFile;
      initFormValue: FileTagInitValue;
      fileModelId: number;
      task: ITaskEntity;
    },
    private matDialogRef: MatDialogRef<ModalUploadFileFromChatComponent>,
    private uploadService: FileManagerService,
    private tasksService: TasksService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private dialogs: DialogService,
    private translocoService: TranslocoService,
    public enums: EnumsService,
    private oipService: OIPService,
  ) {
    this.oipEntityForm.disable();

    this.formFile.controls.subType.valueChanges
      .pipe(filter((r) => this.formFile.controls.subType.valid))
      .subscribe((res) => {
        if (this.file) {
          this.formFile.controls.comment.clearValidators();
        }
      });
  }

  get isDisabled(): boolean {
    return (
      this.formFile.invalid || !this.file || this.oipEntityForm.invalid || this.formFile.controls.oips.value === 'none'
    );
  }

  ngOnInit() {
    this.formFile.controls.type.valueChanges
      .pipe(
        untilDestroyed(this),
        filter((r) => this.formFile.controls.type.valid),
      )
      .subscribe((res) => {
        this.formFile.controls.subType.reset();

        this.subType = res === FolderTypes.INPUT ? Object.values(InputFolderTypes) : Object.values(OutputFolderTypes);

        if (!!this.data.initFormValue && res === this.type.INPUT) {
          this.formFile.get('subType').setValue(this.data.initFormValue.initIncomingTag);
        }

        if (!!this.data.initFormValue && res === this.type.OUTPUT) {
          this.formFile.get('subType').setValue(this.data.initFormValue.initOutgoingTag);
        }
      });

    this.oipEntityForm.controls.requestDate.valueChanges.pipe(untilDestroyed(this)).subscribe((result) => {
      if (this.oipEntityForm.controls.priorityDate.value) {
        return;
      }

      this.oipEntityForm.controls.priorityDate.setValue(result);
    });

    this.fileModelId = this.data.task?.oips?.[0]?.id;

    this.getOip();

    this.formFile.controls.subType.valueChanges.subscribe((result) => {
      if (result === this.enums.inputFolderTypes.ADMISSION_NOTICE) {
        this.oipEntityForm.enable();
        this.oipEntityForm.controls.releaseNumber.disable();
        this.oipEntityForm.controls.releaseUrl.disable();
        this.oipEntityForm.controls.releaseDate.disable();
        this.oipEntityForm.controls.expiryDate.disable();
        this.oipEntityForm.controls.prolongationDate.disable();
      } else if (result === this.enums.inputFolderTypes.SECURITY_DOCUMENT) {
        this.oipEntityForm.enable();
        this.oipEntityForm.controls.requestNumber.disable();
        this.oipEntityForm.controls.requestDate.disable();
        this.oipEntityForm.controls.requestUrl.disable();
        this.oipEntityForm.controls.priorityNumber.disable();
        this.oipEntityForm.controls.priorityDate.disable();
        this.oipEntityForm.controls.priorityUrl.disable();
      } else {
        this.oipEntityForm.disable();
      }
    });

    this.formFile.controls.type.setValue(this.data.type); // if modal opened from status bar
    this.formFile.controls.subType.setValue(this.data.subType); // if modal opened from status bar

    if (this.data.addedFile) {
      this.file = this.data.addedFile;
    }

    this.formFile.controls.oips.valueChanges.pipe(untilDestroyed(this)).subscribe((oip) => {
      this.isCreateOip = oip === 'createOip';

      if ((!oip || oip === 'none' || oip === 'createOip') && !this.isUpload) {
        this.oipEntityForm.reset();
        return;
      }

      if (oip?.id) {
        this.oip = oip;
        this.fileModelId = oip.id;
        this.setOipEntityForm(oip);
      }
    });
  }

  getOip() {
    this.oipService
      .getAllOip()
      .pipe(untilDestroyed(this))
      .subscribe((oips) => {
        this.oips = oips.data;
        this.oips.forEach((oip) => {
          if (oip.id === this.data?.task?.oips?.[0]?.id) {
            this.formFile.get('oips').setValue(oip);
            this.oip = oip;
            if (this.oip) {
              this.setOipEntityForm();
            }
          }
        });
        this.filteredOips = this.formFile.get('oipFilterCtrl').valueChanges.pipe(
          untilDestroyed(this),
          startWith(null),
          map((value) => this.filterOips(value)),
          untilDestroyed(this),
        );
        this.isOipsLoading = false;
      });
  }

  objectComparisonFunction(option, value) {
    return (option?.id && value?.id && option?.id === value?.id) || value === 'createOip';
  }

  openPatentPopup(event: MouseEvent): void {
    event.preventDefault();
    this.dialogs
      .patentChoice({
        title: this.translocoService.translate('patent'),
        release: this.oipEntityForm.get('releaseUrl').value,
      })
      .pipe(
        untilDestroyed(this),
        filter((p) => !!p),
      )
      .subscribe((result) => {
        if (result.release === '') {
          this.oipEntityForm.controls.releaseUrl.setValue(null);
          this.oipEntityForm.markAsDirty();
        } else {
          this.oipEntityForm.controls.releaseUrl.setValue(result.release);
          this.oipEntityForm.markAsDirty();
        }
      });
  }

  closeCommentForm(): void {
    this.formFile.controls.comment.reset('');
    this.showAddComment = false;
  }

  uploadFiles(): void {
    const form = this.oipEntityForm.value;
    this.isUpload = true;

    const { type, subType, comment } = this.formFile.getRawValue();
    this.formFile.disable();

    if (this.isCreateOip) {
      this.createOip(this.file, [type, subType], comment);
    } else {
      this.uploadService
        .uploadLinkFilePublic(FileModels.OIP_MODEL, this.fileModelId, this.file, [type, subType], comment)
        .pipe(
          untilDestroyed(this),
          tap(() => {
            this.oipService.update(this.fileModelId, form).subscribe((val) => {});
          }),
        )
        .subscribe({
          next: (fileEntity) => {
            this.formFile.reset();
            this.file = null;
            this.matDialogRef.close(fileEntity);
          },
          error: (err) => {
            this.dialogs.error(err);
            this.formFile.enable();
          },
        });
    }
  }

  openBidPopup(event: MouseEvent): void {
    event.preventDefault();
    this.dialogs
      .bidChoice({
        title: this.translocoService.translate('bid'),
        request: this.oipEntityForm.get('requestUrl').value,
      })
      .pipe(
        untilDestroyed(this),
        filter((p) => !!p),
      )
      .subscribe((result) => {
        if (result.request === '') {
          this.oipEntityForm.controls.requestUrl.setValue(null);
          this.oipEntityForm.markAsDirty();
        } else {
          this.oipEntityForm.controls.requestUrl.setValue(result.request);
          this.oipEntityForm.markAsDirty();
        }
      });
  }

  openPriorityPopup(event: MouseEvent): void {
    event.preventDefault();
    this.dialogs
      .priorityChoice({
        title: this.translocoService.translate('priority_document'),
        request: this.oipEntityForm.get('priorityUrl').value,
      })
      .pipe(
        untilDestroyed(this),
        filter((p) => !!p),
      )
      .subscribe((result) => {
        if (result.request === '') {
          this.oipEntityForm.controls.priorityUrl.setValue(null);
          this.oipEntityForm.markAsDirty();
        } else {
          this.oipEntityForm.controls.priorityUrl.setValue(result.request);
          this.oipEntityForm.markAsDirty();
        }
      });
  }

  onSelected($event: { data: INormalizedIpRight; index: number }) {
    const { name, requestNumber, requestDate, priorityNumber, priorityDate } = $event.data;

    this.oipEntityForm.controls.name.setValue(name);
    this.oipEntityForm.controls.requestNumber.setValue(requestNumber);
    this.oipEntityForm.controls.requestDate.setValue(requestDate);
    this.oipEntityForm.controls.priorityNumber.setValue(priorityNumber);
    this.oipEntityForm.controls.priorityDate.setValue(priorityDate);
  }

  private setOipEntityForm(oip: IOIPEntity = this.oip) {
    this.oipEntityForm.setValue({
      requestNumber: oip ? oip.requestNumber : this.oip.requestNumber,
      requestDate: oip ? oip.requestDate : this.oip.requestDate,
      requestUrl: oip ? oip.requestUrl : this.oip.requestUrl,
      priorityNumber: oip ? oip.priorityNumber : this.oip.priorityNumber,
      priorityDate: oip ? oip.priorityDate : this.oip.priorityDate,
      priorityUrl: oip ? oip.priorityUrl : this.oip.priorityUrl,

      name: oip ? oip.name : this.oip.name,
      releaseNumber: oip ? oip.releaseNumber : this.oip.releaseNumber,
      releaseUrl: oip ? oip.releaseUrl : this.oip.releaseUrl,
      releaseDate: oip ? oip.releaseDate : this.oip.releaseDate,
      expiryDate: oip ? oip.expiryDate : this.oip.expiryDate,
      prolongationDate: oip ? oip.prolongationDate : this.oip.prolongationDate,
    });
  }

  private filterOips(value: string) {
    if (value == null) {
      return this.oips;
    }
    return this.oips.filter((oip) =>
      this.translocoService
        .translate(oip.name ?? '')
        .toLowerCase()
        .includes(value.toLowerCase()),
    );
  }

  private createOip(file: IPublicFile, [type, subType]: [string, string], comment: string) {
    const oipBody: CreateOipDto = {
      name: this.oipEntityForm.controls.name.value
        ? this.oipEntityForm.controls.name.value
        : `${this.translocoService.translate(this.data.task.oipType)} / ${this.translocoService.translate(
            this.data.task.country,
          )}`,
      countries: [this.data.task.country],
      type: this.data.task.oipType,
      stage: OIPStage.REQUEST,
      companyId: this.data.task.companyId,
      keyRightHolderId: this.data.task.companyId,
      accessAgentId: this.data.task.companyId,
      responsible: {
        id: this.data.task.responsibleId,
      } as IUserEntity,
      agentSideResponsible: {
        id: this.data.task.responsibleId,
      } as IUserEntity,
      requestNumber: this.oipEntityForm.controls.requestNumber.value,
      requestDate: this.oipEntityForm.controls.requestDate.value,
      requestUrl: this.oipEntityForm.controls.requestUrl.value,
      priorityNumber: this.oipEntityForm.controls.priorityNumber.value,
      priorityDate: this.oipEntityForm.controls.priorityDate.value,
      priorityUrl: this.oipEntityForm.controls.priorityUrl.value,
      releaseNumber: this.oipEntityForm.controls.releaseNumber.value,
      releaseUrl: this.oipEntityForm.controls.releaseUrl.value,
      releaseDate: this.oipEntityForm.controls.releaseDate.value,
      expiryDate: this.oipEntityForm.controls.expiryDate.value,
      prolongationDate: this.oipEntityForm.controls.prolongationDate.value,
    };

    return this.oipService
      .create(oipBody)
      .pipe(
        untilDestroyed(this),
        switchMap((oip) => {
          this.fileModelId = oip.id;
          this.setOipEntityForm(oip);

          return this.uploadService.uploadLinkFilePublic(
            FileModels.OIP_MODEL,
            this.fileModelId,
            file,
            [type, subType],
            comment,
          );
        }),
      )
      .subscribe((fileEntity) => {
        this.matDialogRef.close(fileEntity);
        this.file = null;
        this.isUpload = false;
      });
  }
}
