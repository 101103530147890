import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { map, Observable } from 'rxjs';
import { NAME_AI_PARAMS } from '@ipnote/const';
import { selectStateIsAuth } from '#selectors';
import { UserSetAiActions } from '../../store/actions/user.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthSignUpAndSaveAiActionsGuard implements CanActivate {
  constructor(public router: Router, private store: Store<AppState>) {}

  canActivate(): Observable<boolean> | boolean {
    return this.store.select(selectStateIsAuth).pipe(
      map((isAuthenticated: boolean | undefined) => {
        const { queryParams } = this.router.getCurrentNavigation().extractedUrl;

        if (queryParams[NAME_AI_PARAMS]) {
          this.store.dispatch(UserSetAiActions({ aiActions: queryParams[NAME_AI_PARAMS] ?? '' }));
        }

        if (!isAuthenticated) {
          this.router.navigate(['/auth/sign-up']).then();
        } else {
          if (isAuthenticated && queryParams[NAME_AI_PARAMS]) {
            this.router.navigate(['desk/default']).then();
          }
        }
        return Boolean(isAuthenticated);
      }),
    );
  }
}
